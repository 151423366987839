<template>

<Animation3  />

 

  <main id="services" class="what dark">
    <div class="banner container">
      <div class="header-section">
        <h1>{{ content.MainHeading }}</h1>
        <p>{{ content.Description }}</p>
        <br />

        <router-link to="/">
          <Button
            class="submit-btn"
            :name="content.Button"
            type="submit"
            :disable="disable"
          />
        </router-link>

        <br />
        <br />
      </div>
    </div>
  </main>
 


</template>

<script>
import Button from "@/components/Button";

import Animation3 from "@/components/Animation3";


import { LanguageStrings } from "@/languages";
 

export default {
  components: {
    Button,
 
    Animation3
  },
  data() {
    return {
      content: [],
    };
  },

  created() {

    localStorage.getItem("siteLanguage") === "GR"
      ? (this.content = LanguageStrings.gr.NotFoundPage)
      : (this.content = LanguageStrings.en.NotFoundPage);

    console.log(this.content);
    document.querySelector("head title").innerText = this.content.MetaTitle;
  },
  
};
</script>
