<style scoped>
.disable-btn {
  cursor: not-allowed;
  background: #80808040;
  border: none;
  color: gray;
}
</style>
<template>
  <div
   
  
  >
    <button
      class="btn btn-primary submit-btn"
      :class="disable ? 'disable-btn' : ''"
      v-if="type !== 'alternate'"
      :disabled="disable"
    >
      <span class="btn-bg"></span>
      <span class="inner">
        <span class="label">{{ name }}</span>
        <span
          aria-hidden="true"
          class="icon icon--22-button-right-arrow icon--22"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            xmlns="http://www.w3.org/2000/svg"
            class="icon"
          >
            <path
              d="M15.205 12l-3.275 3.275 1.414 1.414 4.243-4.242 1.414-1.415-5.657-5.656L11.93 6.79 15.14 10H3.001v2h12.204z"
              fill="#FFF"
              fill-rule="evenodd"
            />
          </svg>
        </span>
      </span>
    </button>
    <button
      class="btn btn-alternate"
      v-if="type === 'alternate'"
      :class="disable ? 'disable-btn' : ''"
      :disabled="disable"
    >
      <span class="btn-bg"></span>
      <span class="inner">
        <span class="label">{{ name }}</span>
        <span
          aria-hidden="true"
          class="icon icon--22-button-right-arrow icon--22"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            xmlns="http://www.w3.org/2000/svg"
            class="icon"
          >
            <path
              d="M15.205 12l-3.275 3.275 1.414 1.414 4.243-4.242 1.414-1.415-5.657-5.656L11.93 6.79 15.14 10H3.001v2h12.204z"
              fill="#FFF"
              fill-rule="evenodd"
            />
          </svg>
        </span>
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: ["type", "name", "disable"],
};
</script>
