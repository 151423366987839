import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/NewHomeUpdated.vue";

// import Services from "../views/Services.vue";
// import Projects from "../views/Projects.vue";
// import Team from "../views/Team.vue";
// import Jobs from "../views/Jobs.vue";
// import JobDetails from "../views/JobDetails.vue";
// import PrivacyPolicy from "../views/privacyPolicy.vue";
// import Impressum from "../views/Impressum.vue";
// import TeamDetails from "../views/TeamDetails.vue";
// import Blogs from "../views/Blogs.vue";
// import BlogPage from "../views/BlogPage.vue";
// import Contact from "../views/Contact.vue";
// import Apply from "../views/Apply.vue";
import NotFound from "../views/404.vue";

const routesEN = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: { footer: true, isWhite: true },
  },
  {
    path: "/services-old",
    name: "Services Old",
    component: () => import("../views/Services.vue"),
    meta: { isWhite: false },
    props: (route) => ({ section: route.query.section }),
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("../views/Services-copy.vue"),
    meta: { isWhite: false },
    props: (route) => ({ section: route.query.section }),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("../views/privacyPolicy.vue"),
    meta: { isWhite: true },
  },

  {
    path: "/test",
    name: "Test",
    component: () => import("../views/Test.vue"),
    meta: { isWhite: true },
  },

  {
    path: "/impressum",
    name: "Impressum",
    component: () => import("../views/Impressum.vue"),
    meta: { isWhite: true },
  },
  {
    path: "/projects",
    name: "Projects",
    component: () => import("../views/Projects.vue"),
    meta: { isWhite: false },
  },
  {
    path: "/about-us",
    name: "About",
    component: () => import("../views/Team.vue"),
    meta: { isWhite: true },
  },
  {
    path: "/team/:slug",
    name: "TeamDetails",
    props: true,
    component: () => import("../views/TeamDetails.vue"),
    meta: { isWhite: true },
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: () => import("../views/Jobs.vue"),
    meta: { isWhite: true },
  },
  {
    path: "/job/:slug",
    name: "Job Details",
    slug: true,
    component: () => import("../views/JobDetails.vue"),
    meta: { isWhite: true },
  },
  {
    path: "/apply/:slug",
    name: "Apply",
    slug: true,
    component: () => import("../views/Apply.vue"),
    meta: { isWhite: false },
  },
  {
    path: "/blogs",
    name: "Blog",
    component: () => import("../views/Blogs.vue"),
    meta: { isWhite: true },
  },
  {
    path: "/blogPage",
    component: () => import("../views/BlogPage.vue"),
    meta: { isWhite: true },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
    meta: { contactpage: true },
  },

  {
    path: "/:catchAll(.*)",
    component: NotFound,
    meta: { isWhite: false },
  },
];

const routesGR = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: { footer: true, isWhite: true },
  },
  {
    path: "/dienstleistungen",
    name: "Services",
    component: () => import("../views/Services-copy.vue"),
    meta: { isWhite: false },
  },

  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("../views/privacyPolicy.vue"),
    meta: { isWhite: true },
  },

  {
    path: "/test",
    name: "Test",
    component: () => import("../views/Test.vue"),
    meta: { isWhite: true },
  },

  {
    path: "/impressum",
    name: "Impressum",
    component: () => import("../views/Impressum.vue"),
    meta: { isWhite: true },
  },
  {
    path: "/projekte",
    name: "Projects",
    component: () => import("../views/Projects.vue"),
    meta: { isWhite: false },
  },
  {
    path: "/uber-uns",
    name: "About",
    component: () => import("../views/Team.vue"),
    meta: { isWhite: true },
  },
  {
    path: "/uber-uns/:slug",
    name: "TeamDetails",
    props: true,
    component: () => import("../views/TeamDetails.vue"),
    meta: { isWhite: true },
  },
  {
    path: "/karriere",
    name: "Jobs",
    component: () => import("../views/Jobs.vue"),
    meta: { isWhite: true },
  },
  {
    path: "/karriere/:slug",
    name: "Job Details",
    slug: true,
    component: () => import("../views/JobDetails.vue"),
    meta: { isWhite: true },
  },
  {
    path: "/apply/:slug",
    name: "Apply",
    slug: true,
    component: () => import("../views/Apply.vue"),
    meta: { isWhite: false },
  },
  {
    path: "/blogs",
    name: "Blog",
    component: () => import("../views/Blogs.vue"),
    meta: { isWhite: true },
  },
  {
    path: "/blogPage",
    component: () => import("../views/BlogPage.vue"),
    meta: { isWhite: true },
  },
  {
    path: "/kontakt",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
    meta: { contactpage: true },
  },

  {
    path: "/:catchAll(.*)",
    component: NotFound,
    meta: { isWhite: false },
  },
];

var routes = [];

if (location.href.indexOf(".de") > 0) routes = routesGR;
else routes = routesEN;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
