<style scoped>
.u-header__toggle {
  margin: -1.5rem 0 0 -0.8125rem;
  pointer-events: auto;
  position: absolute;
  top: 50%;
  z-index: 2;
}
.m-hamburger {
  display: inline-block;
  height: 3rem;
  width: 3rem;
  position: fixed;
  top: 50px;
  left: 35px;
  /* background: red; */
  z-index: 500;
}

.m-hamburger.white .m-hamburger__menu {
  color: white;
}

@media screen and (max-width: 575px) {
  .m-hamburger {
    left: 10px;
  }
}

@media screen and (min-width: 900px) {
  .m-hamburger {
    display: none;
  }
}

.m-hamburger,
abbr {
  text-decoration: none;
}

.m-hamburger__inner {
  -webkit-animation: hamburger-inactive 0.5s ease both;
  animation: hamburger-inactive 0.5s ease both;
  display: block;
  height: 1.375rem;
  margin: 0.8125rem;
  position: relative;
  width: 1.375rem;
  transition: 3s ease-in all;
}

@keyframes hamburger-icon-animation-1 {
  0% {
    transform: rotate(0deg);
    left: 0;
    top: 0;
  }
  100% {
    transform: rotate(90deg);
    left: 8px;
    top: 11px;
    color: white;
  }
}

@keyframes hamburger-icon-animation-close-1 {
  0% {
    transform: rotate(90deg);
    left: 8px;
    top: 11px;
    color: white;
  }
  100% {
    transform: rotate(0deg);
    left: 0;
    top: 0;
  }
}

@keyframes hamburger-icon-animation-2 {
  0% {
    transform: rotate(0deg);
    left: 0;
    top: 0;
  }
  100% {
    transform: rotate(-90deg);
    top: -4px;
    left: -4px;
    color: white;
  }
}

@keyframes hamburger-icon-animation-close-2 {
  0% {
    transform: rotate(-90deg);
    top: -4px;
    left: -4px;
    color: white;
  }
  100% {
    transform: rotate(0deg);
    left: 0;
    top: 0;
  }
}

.m-hamburger__menu--1 {
  /* -webkit-animation-name: hamburger-menu-inactive-1;
  animation-name: hamburger-menu-inactive-1; */
}

.m-hamburger__menu--1.animate-open {
  animation: hamburger-icon-animation-1 0.5s ease-in forwards;
}

.m-hamburger__menu--1.animate-close {
  animation: hamburger-icon-animation-close-1 0.5s ease-in forwards;
}

.m-hamburger__menu {
  /* -webkit-animation: hamburger-menu-inactive 0.5s ease both;
  animation: hamburger-menu-inactive 0.5s ease both; */
  background: currentColor;
  display: block;
  height: 2px;
  margin: -1px 0 0;
  position: relative;
  /* top: 50%; */
  width: 100%;
}

.m-hamburger__menu--2 {
  /* -webkit-animation-name: hamburger-menu-active-2; */
  /* animation-name: hamburger-menu-inactive-2; */
  margin-top: 13px;
}

.m-hamburger__menu--2.animate-open {
  animation: hamburger-icon-animation-2 0.5s ease-in forwards;
}

.m-hamburger__menu--2.animate-close {
  animation: hamburger-icon-animation-close-2 0.5s ease-in forwards;
}

.u-header__toggle-close {
  margin: -0.5rem 0 0 -0.8125rem;
  pointer-events: auto;
  position: absolute;
  /* top: 50%; */
  z-index: 2;
  transform: rotate(90deg);
  transition: transform 1s linear;
  color: #ffffff;
}
.drawer-text {
  height: 30px;
  background-color: black;
  color: #ffffff;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 60px;
}

.animation-paused {
  animation-play-state: paused !important;
}
</style>

<template>
  <div class="drawermobile">
    <a-radio-group
      style="margin-right: 8px"
      :default-value="placement"
      @change="onChange"
    >
      <!-- <a-radio value="top">
        top
      </a-radio> -->
    </a-radio-group>
    <!-- <a-button style="border: 1px solid #ae0000;" @click="showDrawer">
      <i class="fa fa-bars" aria-hidden="true" style="color: black;"></i>
    </a-button> -->
    <Teleport to="body">
      <button
        aria-label="Menu"
        class="u-header__toggle m-hamburger"
        :class="
          $route.name === 'HomePage' ||
          $route.name === 'About' ||
          $route.name === 'Jobs'
            ? 'white'
            : 'white'
        "
        @click="toggleDrawer"
      >
        <span aria-hidden="true" class="m-hamburger__inner"
          ><span class="m-hamburger__menu m-hamburger__menu--1"></span>
          <span class="m-hamburger__menu m-hamburger__menu--2"></span
        ></span>
        <!---->
      </button>
    </Teleport>

    <a-drawer
      :placement="placement"
      :closable="true"
      :visible="visible"
      @close="onClose"
    >
      <!-- <div @click="onClose" class="closesignicon">
        <div><i class="fas fa-times cross-faicon"></i></div>
      </div> -->

      <!-- <div v-if="(this.visible = true)"> -->
      <button
        aria-label="Menu"
        class="closesignicon u-header__toggle-close m-hamburger"
        @click="onClose"
        hidden
      >
        <span aria-hidden="true" class="m-hamburger__inner"
          ><span class="m-hamburger__menu m-hamburger__menu--1"></span>
          <span class="m-hamburger__menu m-hamburger__menu--2"></span
        ></span>
        <!---->
      </button>
      <!-- </div> -->
      <!-- <div v-else>
        <h1>bp</h1>
      </div> -->

      <p class="drawer-text">
        <router-link :to="{ name: 'Services' }" @click="onClose"
          >{{ content.Services }}
        </router-link>
      </p>
      <p class="drawer-text">
        <router-link :to="{ name: 'Projects' }" @click="onClose">{{
          content.Projects
        }}</router-link>
      </p>
      <p class="drawer-text">
        <router-link
          @click="onClose"
          v-bind:class="{
            routerLinkActive:
              getRoutePath === 'About' || getRoutePath === 'TeamDetails',
          }"
          :to="{ name: 'About' }"
          >{{ content.Team }}</router-link
        >
      </p>

      <p class="drawer-text">
        <router-link
          @click="onClose"
          v-bind:class="{
            routerLinkActive:
              getRoutePath === 'Jobs' || getRoutePath === 'JobDetails',
          }"
          :to="{ name: 'Jobs' }"
          >{{ content.Jobs }}</router-link
        >
      </p>

      <p class="drawer-text">
        <a
          href="https://blogs.gap-dynamics.com/"
          rel="noopener noreferrer"
          @click="onClose"
          >{{ content.Blog }}</a
        >
      </p>
      <p class="drawer-text">
        <router-link :to="{ name: 'Contact' }" @click="onClose">{{
          content.Contact
        }}</router-link>
      </p>
      <p class="drawer-text consult-btn">
        <a target="_blank" href="https://calendly.com/gap-dynamics/">
          Book A Free Consultation
        </a>
      </p>
    </a-drawer>
  </div>
</template>
<script>
import { LanguageStrings } from "@/languages";

export default {
  data() {
    return {
      visible: false,
      placement: "left",
      content: null,
    };
  },
  methods: {
    showDrawer() {
      this.visible = true;
    },
    toggleDrawer() {
      let hamburgerIcon1 = document.querySelector(".m-hamburger__menu--1");
      let hamburgerIcon2 = document.querySelector(".m-hamburger__menu--2");
      if (this.visible) {
        this.visible = false;
        hamburgerIcon1.classList.add("animate-close");
        hamburgerIcon2.classList.add("animate-close");
        hamburgerIcon1.addEventListener(
          "animationend",
          () => {
            hamburgerIcon1.classList.remove("animate-open");
            hamburgerIcon1.classList.remove("animate-close");
          },
          { once: true }
        );
        hamburgerIcon2.addEventListener(
          "animationend",
          () => {
            hamburgerIcon2.classList.remove("animate-open");
            hamburgerIcon2.classList.remove("animate-close");
          },
          { once: true }
        );
      } else {
        this.visible = true;
        hamburgerIcon1.classList.add("animate-open");
        hamburgerIcon2.classList.add("animate-open");
        // hamburgerIcon1.addEventListener(
        //   "animationiteration",
        //   () => {
        //     hamburgerIcon1.classList.add("animation-paused");
        //   },
        //   { once: true }
        // );
        // hamburgerIcon2.addEventListener(
        //   "animationiteration",
        //   () => {
        //     hamburgerIcon2.classList.add("animation-paused");
        //   },
        //   { once: true }
        // );
      }
    },
    onClose() {
      // this.visible = false;
      this.toggleDrawer();
    },

    onChange(e) {
      this.placement = e.target.value;
    },
  },

  created() {
    localStorage.getItem("siteLanguage") === "GR"
      ? (this.content = LanguageStrings.gr.Menu)
      : (this.content = LanguageStrings.en.Menu);
  },
};
</script>
