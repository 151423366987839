<template>
  <header
    v-if="getRoutePath != 'HomePage' || AnimCheck"
    v-bind:class="{
      fixed: isFixed,
      'transparent-header':
        (getRoutePath === 'TeamDetails' || getRoutePath === 'privacy-policy') &&
        isTransparent,
      'translucent-header': getRoutePath === 'TeamDetails' && isTranslucent,
      'header-grey':
        (getRoutePath === 'Contact' || getRoutePath === 'Jobs') && isDesktop,
      Homealternate: getRoutePath === 'HomePage',
      alternate: isDesktop
        ? getRoutePath === 'Job Details' ||
          getRoutePath === 'about-us' ||
          getRoutePath === 'Impressum' ||
          getRoutePath === 'TeamDetails' ||
          getRoutePath === 'TeamDetails' ||
          getRoutePath === 'privacy-policy'
        : true,
    }"
  >
    <b v-bind:class="{ active: isFixed }"> </b>
    <div id="nav" class="container-fluid">
      <DrawerMobile />

      <div class="left">
        <router-link
          v-if="!$route.meta.isWhite"
          class="logo"
          :to="{ name: 'HomePage' }"
        >
          <BlackLogo v-if="isDesktop" />
          <Logo v-if="!isDesktop" />
        </router-link>

        <router-link v-else class="logo" :to="{ name: 'HomePage' }">
          <BlackLogo
            v-if="getRoutePath === 'Jobs' || getRoutePath === 'About'"
          />
          <Logo v-else />
        </router-link>
      </div>
      <div class="mobile-layout-placeholder"></div>

      <div class="right">
        <ul class="flex head-list">
          <li>
            <router-link :to="{ name: 'Services' }">{{
              content.Services
            }}</router-link>
          </li>

          <li>
            <router-link :to="{ name: 'Projects' }">{{
              content.Projects
            }}</router-link>
          </li>

          <li>
            <router-link
              v-bind:class="{
                routerLinkActive:
                  getRoutePath === 'About' || getRoutePath === 'TeamDetails',
              }"
              :to="{ name: 'About' }"
              >{{ content.Team }}</router-link
            >
          </li>
          <li>
            <router-link
              v-bind:class="{
                routerLinkActive:
                  getRoutePath === 'Jobs' || getRoutePath === 'JobDetails',
              }"
              :to="{ name: 'Jobs' }"
              >{{ content.Jobs }}</router-link
            >
          </li>
          <li>
            <!-- <router-link :to="{ name: 'Blogs' }">Blogs</router-link> -->
            <!-- <router-link
              v-bind:class="{
                routerLinkActive: getRoutePath === 'Blog',
              }"
              :to="{ name: 'Blog' }"
              >Blogs</router-link
            > -->
            <a
              v-bind:class="{
                routerLinkActive: getRoutePath === 'Blog',
              }"
              href="https://blogs.gap-dynamics.com/"
            >
              Blogs
            </a>
          </li>
          <li>
            <router-link :to="{ name: 'Contact' }">{{
              content.Contact
            }}</router-link>
          </li>
          <li class="consult-btn">
            <a target="_blank" href="https://calendly.com/gap-dynamics/">
              Book A Free Consultation
            </a>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>
<style>
#nav .anim_logo {
  width: 70px;
  margin-left: 20px;
}

#nav .anim_logo .cls-7-black {
  fill: black;
}

#nav .anim_logo .cls7-brown {
  fill: #1a1a1a;
}
@media (max-width: 575px) {
  #nav .anim_logo {
    margin-left: 0px;
  }
  .u-header__toggle {
    margin-left: 18px !important;
  }
}
/* .cls-1 {
  fill: #ccc;
}
.cls-2 {
  fill: #07aa07;
}
.cls-3 {
  fill: #00f000;
}
.cls-4 {
  fill: #06cc06;
}
.cls-5 {
  fill: #9b0e0e;
}
.cls-6 {
  fill: #f00000;
}
.cls-7 {
  fill: #c60a0a;
}
.cls-8 {
  fill: #fff;
} */

.company-text {
  fill: #1a1a1a;
}

.dark .company-text,
.transparent .company-text,
.alternate .company-text {
  fill: #fff;
}
</style>
<script>
import { useRoute } from "vue-router";
// import Logo from "@/assets/icons/logo_white.svg";
import Logo from "@/assets/imgs/company-logo-03.svg";
// import BlackLogo from "@/assets/icons/logo_black-updated-01.svg";
import BlackLogo from "@/assets/imgs/company-logo-03-black.svg";
import DrawerMobile from "../DrawerMobile";

import { LanguageStrings } from "@/languages";
export default {
  name: "Header",
  components: {
    Logo,
    DrawerMobile,
    BlackLogo,
  },
  // computed: {
  //   currentRouteName(){
  //     console.log(useRoute().name)
  //     return useRoute().name
  //   }
  // },
  created() {
    window.addEventListener("scroll", this.handleScroll);

    localStorage.getItem("siteLanguage") == "GR"
      ? (this.content = LanguageStrings.gr.Menu)
      : (this.content = LanguageStrings.en.Menu);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    isMobile() {
      if (screen.width < 768) {
        return true;
      } else {
        return false;
      }
    },
    checkIsDesktop() {
      if (window.innerWidth > 768) {
        this.isDesktop = true;
      } else {
        this.isDesktop = false;
      }
    },
    handleScroll() {
      // Any code to be executed when the window is scrolled
      if (window.scrollY > 0) {
        // document.querySelector("body").style.paddingBottom = "100px";
        this.isFixed = true;
        this.isTransparent = false;
        this.isTranslucent = true;
      } else {
        this.isFixed = false;
        this.isTransparent = true;
        this.isTranslucent = false;
      }
    },
    afterVisibleChange(val) {
      console.log("visible", val);
    },
    // showDrawer() {

    //   this.visible = true;
    // },
    showDrawer: function() {
      this.visible = true;
    },

    onClose: function() {
      this.visible = false;
    },
  },
  computed: {
    getRoutePath() {
      /*
      if (this.$route.name === "Jobs" || this.$route.name === "About") {
        document.querySelector("body").classList.add("alternate");
      } else {
        document.querySelector("body").classList.remove("alternate");
      }

      if (this.$route.name === "TeamDetails") {
        document.querySelector("body").classList.add("transparent");
      } else {
        document.querySelector("body").classList.remove("transparent");
      }*/

      return this.$route.name;
    },
  },
  props: {
    msg: String,
  },
  data() {
    return {
      route: useRoute().name,
      isFixed: false,
      visible: false,
      content: null,
      AnimCheck: this.isMobile() ? true : localStorage.getItem("Load"),
      headerVisible: false,
      isTransparent: true,
      isDesktop: true,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.AnimCheck = true;
    }, 8500);
    this.checkIsDesktop();
    window.addEventListener("resize", () => {
      this.checkIsDesktop();
    });
  },
};
</script>
