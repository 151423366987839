<template>
  <div>
    <Header />
    <router-view />
    <Footer v-if="!$route.meta.footer" />
  </div>
</template>

<script lang="ts">
// import FirstAnimation from "./components/HomePageAnimation/index.vue";

// import  Header from "./components/MenuMobile/MenuMobile.vue";
import Header from "./components/Layout/Header.vue";
import Footer from "./components/Layout/Footer.vue";

export default {
  name: "App",
  // metaInfo: {
  //   title: 'Home',
  //   titleTemplate: '%s | Gap Dynamics',
  //   htmlAttrs: {
  //     lang: 'en-US'
  //   },
  //   meta: [
  //     { charset: 'utf-8' },
  //     { name: 'description', content: 'An example Vue application with vue-meta.' },
  //     { name: 'viewport', content: 'width=device-width, initial-scale=1' }
  //   ]
  // },
  components: {
    Header,
    Footer,
    // FirstAnimation
    // Loader
  },
  data() {
    return {
      message: "",
      elementVisible: true,
      AnimCheck: localStorage.getItem("Load"),
    };
  },
  computed: {
    getRoutePath() {
      return this.$route.name;
    },
  },
  async created() {
    if (location.href.indexOf(".de") > 0)
      localStorage.setItem("siteLanguage", "GR");
    else localStorage.setItem("siteLanguage", "EN");

    if (
      !localStorage.getItem("localClientIp") ||
      localStorage.getItem("localClientIp") == "undefined"
    ) {
      try {
        const request = await fetch(
          "https://ipinfo.io/json?token=8ba708b10659d2"
        );
        const data = await request.json();

        await localStorage.setItem("localClientIp", data.country);
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    // isMobile() {
    //   if (screen.width <= 900) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
};
</script>
