export const LanguageStrings = {
  en: {
    Menu: {
      Home: "Home",
      Services: "Services",
      Projects: "Projects",
      Team: "About us",
      Jobs: "Careers",
      Contact: "Contact",
      Blog: "Blogs",
    },
    ProjectPage: {
      MetaTitle: "Gap Dynamics | Successful Software Engineering Projects",
      Heading1: "CHECK OUT A FEW",
      Heading2: "RECENT PROJECTS",
      Description:
        "From one of the best iOS and Android applications to ingenious, smooth web development. Yes, we do build products that thrive but we also scale your business to another level meeting your business and user needs.",
    },
    NotFoundPage: {
      MetaTitle: "404 | Gap Dynamics Official Website",
      MainHeading: "404 Page Not Found!",
      Description:
        "We're sorry, but we can't find the page you were looking for. The requested URL was not found on this server.",
      Button: "BACK TO HOME",
    },
    Footer: {
      OneLineDescription: "READY TO SWIPE RIGHT?",
      Email: "LET'S CHAT",
      mailto: "mailto:hello@gap-dynamics.com",
      GermanPhoneNo: "+49(176)62775566",
      PakistanPhoneNo: "+92(051)874-0983",
      CopyRightText: " Gap Dynamics",
      PrivacyPolicyPageText: "Privacy policy",
      AltText: "EMAIL US",
      FooterOneLineDescription: "WANT TO TALK TO US ABOUT SOMETHING ELSE?",
      FooterEmail: "HELLO@GAP-DYNAMICS.COM",
      establishText: "Established 2009",
      digitalText: "Digital Transformation",
      blockchainText: "Blockchain",
      webText: "Web",
      appText: "App",
      designText: "Design & UI/UX",
      marketingText: "Digital Marketing",
      serviceText: "Services",
      projectsText: "Projects",
      teamText: "Team",
      careersText: "Careers",
      blogText: "Blog",
      contactText: "Contact",
    },
    HomePage: {
      MetaTitle:
        "Gap dynamics is an international software house that builds custom software",
      Slider1: {
        Title: ["WE TRANSFORM COMPANIES"],
        Description:
          "We transform businesses by bringing imagination to life, building solutions and stimulating growth.",
        ButtonText: "REGISTER FOR CONSULTATION",
      },
      Slider2: {
        Title: "WE ENDED CHOICE PARALYSIS ",
        Description: "App  •  Strategy",
        Description1: "Web  •  Strategy",
        ButtonText: "",
      },
      Slider3: {
        Title: "WE HELPED GROW SALES",
        Description: "Experts in Digital Transformation ",
        ButtonText: "",
      },
      Slider4: {
        Title: "WE FOCUS ON WINNING",
        Description: "Experts in Digital Transformation",
        ButtonText: "",
      },
      Slider5: {
        // Title: "CUSTOM WEB & MOBILE APP DEVELOPMENT COMPANY",
        Title: "WE PRESCRIBED EFFICIENCY",
        Description: "IOS • Android • App • Strategy",
        Description1: "IOS • Android • Web • Strategy",
        ButtonText: "",
      },
      Slider6: {
        Title: "YOUR ONLY COMPATIBLE PARTNER",
        Description: "Marketing Strategy",
        ButtonText: "",
      },
      Slider7: {
        Title: "WE EQUIP SPEED, PRECISION, AND QUALITY",
        Description: {
          heading: "OUR APPROACH",
          details:
            "The client is god. It might be an old rule for some, but we still live by it. Our strategy towards the results resolves around the requirements provided, but our inputs are loved by the clients 95% of the time!",
        },
        ButtonText: "",
      },
      Slider8: {
        Title: "ENGINEERED FOR EXCELLENCE",
        Description:
          "Optimize your online portals, develop complex websites and create tailor-made enterprise systems for an improved web presence. ",
        Description2: "Web • Strategy",
        ButtonText: "",
      },
      Slider9: {
        Title: "WE INVEST IN EXCELLENCE",
        Description: "Web • Strategy",
        ButtonText: "",
      },
      Slider10: {
        Title: "WE FIRED CHECK-IN LiNES",
        Description:
          "We transform businesses by bringing imagination to life, building solutions and stimulating growth.",
        ButtonText: "REGISTER FOR CONSULTATION",
      },
      Slider11: {
        Title: "WE SHARED INVESTMENTS",
        Description:
          "We transform businesses by bringing imagination to life, building solutions and stimulating growth.",
        ButtonText: "REGISTER FOR CONSULTATION",
      },
      Slider12: {
        Title: "WE GET YOU NOTICED",
        Description:
          "We transform businesses by bringing imagination to life, building solutions and stimulating growth.",
        ButtonText: "REGISTER FOR CONSULTATION",
      },
      Slider13: {
        Title: "WHAT’S OUR SECRET?",
        Description:
          "We transform businesses by bringing imagination to life, building solutions and stimulating growth.",
        ButtonText: "REGISTER FOR CONSULTATION",
      },
      Process: {
        Title: "OUR PROCESS",
        Description:
          "It's our playbook for client success. It’s the infrastructure that allows our team to create the great things that drive results for our clients.",
      },
      Peoples: {
        Title: "OUR PEOPLE",
        Description:
          "Who come from startups, not agencies. Instead of obsessing over awards, they’ve focused on satisfying customers and users. They’re ex-CEOs, ex-CTOs, ex-CMOs—people who know how to win in the marketplace.",
      },
      FooterHeading1: "READY TO SWIPE RIGHT?",
      FooterHeading2: "LET'S CHAT",
      PrivacyPolicy: "Privacy policy",
      CopyRightText: "Gap Dynamics",
      ScrollButtonText: "More about us",
      establishText: "Established 2009",
      digitalText: "Digital Transformation",
      blockchainText: "Blockchain",
      webText: "Web",
      appText: "App",
      designText: "Design & UI/UX",
      marketingText: "Digital Marketing",
      serviceText: "Services",
      projectsText: "Projects",
      teamText: "Team",
      careersText: "Careers",
      blogText: "Blog",
      contactText: "Contact",
    },
    ServicesPage: {
      MetaTitle: "Gap Dynamics offers the best software development services",
      SuperScriptTitle: "REDEFINING",
      Mainheading:
        "WE CREATE THE DIGITAL EXPERIENCES,PRODUCTS, AND VENTURES OF TOMORROW",
      Description:
        "We are a full-service IT company with robust practitioners in the field of Blockchain, Web design and development, App design and development, ERP solutions development, Branding, Digital Marketing, SEO, Paid Media, and Opportunity Acquisition. Our digital experiences are human-centered and future-proof to grow your revenue in the long run. Guaranteed.",
      Points: [
        "Digital Strategy & ROI Planning",
        "Customer Experience & Marketing Automation",
        "Business Analytics & Data Science",
        "Governance, Risk & Compliance",
        "Opportunity Analysis",
      ],
      Points_ButtonText: "WANT TO CHAT ABOUT YOUR IDEA?",
      ButtonText: "What We Do",
      Blockchain: {
        MainTitle: "BLOCKCHAIN DEVELOPMENT",
        SubHeading: "LEADING THE WAY TOWARDS A MORE DECENTRALIZED WORLD",
        Description: [
          "Gap Dynamics helps startups and enterprise companies bring their products to the blockchain. Innovations in blockchain technology are propelling us toward new standards for financial and business systems worldwide. Our team of blockchain developers, product experts, and industry specialists is here to help forward-thinking companies stay on top of the technological demands of the future.",

          "Gap Dynamics provides bespoke Blockchain and Web 3.0 Development services in E-Commerce Solutions and Mobile Applications. Gap Dynamics offers a wide range of bespoke, customized and innovative Blockchain development solutions. Whether its NFTs, DeFi, DEX Aggregators.",
        ],
        Points: [
          "Blockchain Technology Consulting",
          "Public & Private Blockchain Development",
          "DApp Design & Development",
          "Cryptocurrency Consulting & Wallet Development",
          "Blockchain Security Auditing",
        ],
      },
      EnterpriseApp: {
        MainTitle: "ENTERPRISE APPLICATION DEVELOPMENT",
        Description: [
          "With ERP you can operate your business seamlessly. Whether you are an established business or a developing one, a reliable ERP solution can cut down your cost and empower your employees at the same time.",
          "Our skilled ERP developers are experts in setting up management, tracking, booking software, and uniting the rest of your business operations within a sole, easy, and custom ERP solution.",
        ],
        Points: ["CRM", "POS", "CMS", "ERP", "CDP"],
      },

      Cyber: {
        MainTitle: "CYBERSECURITY SERVICES",
        SubHeading: "YOUR CYBERSECURITY SOLUTIONS PARTNER",
        Description: [
          "Cyber security is how a company safeguards its digital networks, programs, devices, systems, servers, online assets, and data security. As a Managed Security Services Provider (MSSP), we act as our client’s trusted go-to partner bringing advanced expertise in the current threat landscape. Let us become an extension of your team, whether seeking assistance with 24x7 network security monitoring, Virtual CISO Services, or Penetration Testing.",
          "Gap Dynamics offer on-demand expertise combined with the nonstop threat detection and response required to block attacks before they damage your business. Optimize efficiency while minimizing cost, gaining the backing you need when you need us the most. Monitor and analyze data and trends within a single unified dashboard, accessing logs at any time. We help organizations respond to threats faster and leverage the latest and most sophisticated cybersecurity technology while retaining a well-documented audit trail.",
        ],
        Points: [
          "Application Security",
          "Network Infrastructure Security",
          "Red Teaming",
          "Social Engineering Testing",
          "Wireless Penetration Testing",
        ],
      },
      App: {
        MainTitle: "APP DEVELOPMENT",
        SubHeading: "HAVE AN IDEA? MAKE IT INTO A REALITY!",
        Description:
          "We are here to bring your innovative imaginations to life. Our team of expert and experienced app developers will assist you throughout the planning, development, monitoring, and optimization processes. We strategize as per the nature of a project and implement the strategy by using the most relevant methodologies. A flawless performance delivery, guaranteed.",
        Points: [
          "iOS Development",
          "Android Development",
          "React native Development",
          "Flutter Development",
        ],
      },
      Web: {
        MainTitle: "WEB DEVELOPMENT",
        // SubHeading: "YOUR WEBSITE IS YOUR BEST MARKETING TOOL.",
        SubHeading: "WEBSITE, THE BEST MARKETING TOOL",
        Description:
          "We blend imagination with technology to develop the best possible website for your business. Our team of skilled developers utilizes the most advanced technologies to build websites that are well designed and deliver striking performance and user engagement.",
        Points: [
          "React/Gatsby",
          "Vue.js",
          "Python/Django",
          "Node",
          "E-Commerce Web Development",
        ],
      },
      UIUX: {
        MainTitle: "DESIGN & UI/UX",
        // SubHeading:
        // "WE TRANSFORM USER’S DIGITAL EXPERIENCE USING HUMAN PSYCHOLOGY AND BEHAVIOURAL SCIENCE.",
        SubHeading: "TRANSFORM USER EXPERIENCES",
        Description: [
          // enter p1
          "Our UI/UX designers turn your imagination into the final product. A good web design is one that guides the user towards its goal, makes the journey easy and memorable and our team provides exactly that by designing mobile apps and software customized to meet your objectives.",
          // enter p2
          "We take organizations to the next level by designing and building platforms and digital experiences that go to the crux of your organization’s needs.",
          // enter p3
          "Whether it is a SaaS product, a dashboard, or a social networking app you require — we’ve got your back.",
        ],
        Points: [
          "Logo and Branding",
          "User Experience",
          "Information Architecture",
          "Prototyping",
          "Mobile and Web Design",
        ],
      },
      GrowthMarketing: {
        MainTitle: "GROWTH MARKETING",
        // SubHeading: "WE HELP BUSINESSES GROW 10X WITH GROWTH MARKETING",

        SubHeading: "GROW 10x WITH GROWTH MARKETING",
        Description: [
          "We take care of growing your business through Paid Media & Social Media so that you can take care of your business operations.",
          "We have 5 years of experience growing businesses across Europe. Our Growth Hacking team is packed with experts in Paid Ads, Influencer Marketing and Social Media. Our costs are affordable and our results are amazing.",
        ],
        Points: [
          "Marketing Automation & Lifecycle Campaigns",
          "Analytics & Insights",
          "Central Data Management",
          "Attribution Tracking & Optimizations",
          "A/B Testing & Workflow Optimizations",
        ],
      },
      DigitalMarketing: {
        MainTitle: "DIGITAL MARKETING",
        Description: [
          "Our team of Digital Marketers is itching to create marketing strategies that will skyrocket your revenue. We maintain and grow your brand by taking care of its digital marketing. Be it graphic designing, content creation, social media strategy, or Email marketing -- We’ve got you covered.",
          "One thing each of our team members has is their thirst for knowledge. And one thing that separates them from other digital marketers is that we have the skill to craft a listening brand right from the scratch.",
        ],
        Points: [
          "SEO & ASO",
          "Paid Media Planning & Implementation",
          "Campaign POI Monitoring & Reporting",
        ],
      },
      NextGen: {
        MainTitle: "NEXT-GEN TECH SPECIALTIES",
        // SubHeading: "INTRODUCING THE TECHNOLOGY OF TOMORROW... TODAY",
        SubHeading: "INTRODUCING THE TECHNOLOGY OF TOMMORROW ",
        Description: [
          "Gimmick or next big thing? Sometimes it’s a fine line. We love experimenting with emerging tech, which ensures our stack is future-proofed and when something moves from toy to transformative, we have a nice head start.",
        ],
        Points: [
          "AR/VR",
          "Connected Hardware",
          "Blockchain/dApps",
          "Chatbots",
          "Alexa/Google Home",
        ],
      },
      TestimonialsSectionTitle: "Don't take our word for it",
      testimonial_data: [
        {
          testimonial:
            "“When our own skills did not manage to get us where we wanted, Gap Dynamics took care of the rest. The expertise, customer service and follow up we experienced from Gap Dynamics were simply flawless.”",
          author: "Andrew Mark, Product Manager",
          link: "",
          bg: "dark-blue",
        },
        {
          testimonial:
            "“Gap Dynamics team members proved to be extremely flexible and responsive. They stayed in daily contact with us, which allowed us to adjust the scope of works promptly and implement new requirements on the fly. Additionally, the team delivered demos every other week so that we could be sure that the invoicing system aligned with our business needs.”",
          author: "Sajid Malik, Associate Director,",
          link: "LS",
          bg: "red",
        },
        {
          testimonial:
            "“We have worked with Gap Dynamics on various projects, and find that they provide quality service and expertise for our programming needs. It is rare to find a service provider with such professional consistency - they are a valued service provider to our business!”",
          author: "Hammad, Ecommerce Product Owner",
          link: "ZF",
          bg: "purple",
        },
        {
          testimonial:
            "“Gap Dynamics has been supporting our business for the past 9 months in both the creation and implementation of new and tailored software. We have worked with several of their developers and cannot speak highly enough of the team. They are reliable, thorough, smart, available, extremely good communicators and very friendly! We would recommend hiring Gap Dynamics to anyone looking for a highly productive and solution driven team. We plan to continue to work with them for the long term.”",
          author: "Hammad, Ecommerce Product Owner",
          link: "ZF",
          bg: "green",
        },
        {
          testimonial:
            "“GAP DYNAMICS WORKED WITH OUR IN-HOUSE IT TEAM TO CREATE A STRAIGHTFORWARD USER EXPERIENCE WITHIN A STRICT TIME FRAME. THOUGHOUT THE DEVELOPMENT PROCESS, THEY COMMUNICATED WELL AND PROVIDED EXPERIENCED ADVICE WHICH LED US TO VICTORY.”",
          author: "Hammad, Ecommerce Product Owner",
          link: "ZF",
          bg: "blue",
        },
      ],
    },
    ServicesCopyPage: {
      MetaTitle: "Gap Dynamics offers the best software development services",
      SuperScriptTitle: "REDEFINING",
      Mainheading:
        "WE CREATE THE DIGITAL EXPERIENCES,PRODUCTS, AND VENTURES OF TOMORROW",
      Description:
        "We are a full-service IT company with robust practitioners in the field of Blockchain, Web design and development, App design and development, ERP solutions development, Branding, Digital Marketing, SEO, Paid Media, and Opportunity Acquisition. Our digital experiences are human-centered and future-proof to grow your revenue in the long run. Guaranteed.",
      Points: [
        "Digital Strategy & ROI Planning",
        "Customer Experience & Marketing Automation",
        "Business Analytics & Data Science",
        "Governance, Risk & Compliance",
        "Opportunity Analysis",
      ],
      Points_ButtonText: "WANT TO CHAT ABOUT YOUR IDEA?",
      ButtonText: "What We Do",
      Blockchain: {
        MainTitle: "BLOCKCHAIN DEVELOPMENT",
        SubHeading: "LEADING THE WAY TOWARDS A MORE DECENTRALIZED WORLD",
        Description: [
          "Gap Dynamics helps startups and enterprise companies bring their products to the blockchain. Innovations in blockchain technology are propelling us toward new standards for financial and business systems worldwide. Our team of blockchain developers, product experts, and industry specialists is here to help forward-thinking companies stay on top of the technological demands of the future.",

          "Gap Dynamics provides bespoke Blockchain and Web 3.0 Development services in E-Commerce Solutions and Mobile Applications. Gap Dynamics offers a wide range of bespoke, customized and innovative Blockchain development solutions. Whether its NFTs, DeFi, DEX Aggregators.",
        ],
        Points: [
          "Blockchain Technology Consulting",
          "Public & Private Blockchain Development",
          "DApp Design & Development",
          "Cryptocurrency Consulting & Wallet Development",
          "Blockchain Security Auditing",
        ],
      },
      EnterpriseApp: {
        MainTitle: "ENTERPRISE APPLICATION DEVELOPMENT",
        Description: [
          "With ERP you can operate your business seamlessly. Whether you are an established business or a developing one, a reliable ERP solution can cut down your cost and empower your employees at the same time.",
          "Our skilled ERP developers are experts in setting up management, tracking, booking software, and uniting the rest of your business operations within a sole, easy, and custom ERP solution.",
        ],
        Points: ["CRM", "POS", "CMS", "ERP", "CDP"],
      },

      Cyber: {
        MainTitle: "CYBERSECURITY SERVICES",
        SubHeading: "YOUR CYBERSECURITY SOLUTIONS PARTNER",
        Description: [
          "Cyber security is how a company safeguards its digital networks, programs, devices, systems, servers, online assets, and data security. As a Managed Security Services Provider (MSSP), we act as our client’s trusted go-to partner bringing advanced expertise in the current threat landscape. Let us become an extension of your team, whether seeking assistance with 24x7 network security monitoring, Virtual CISO Services, or Penetration Testing.",
          "Gap Dynamics offer on-demand expertise combined with the nonstop threat detection and response required to block attacks before they damage your business. Optimize efficiency while minimizing cost, gaining the backing you need when you need us the most. Monitor and analyze data and trends within a single unified dashboard, accessing logs at any time. We help organizations respond to threats faster and leverage the latest and most sophisticated cybersecurity technology while retaining a well-documented audit trail.",
        ],
        Points: [
          "Application Security",
          "Network Infrastructure Security",
          "Red Teaming",
          "Social Engineering Testing",
          "Wireless Penetration Testing",
        ],
      },
      App: {
        MainTitle: "APP DEVELOPMENT",
        SubHeading: "HAVE AN IDEA? MAKE IT INTO A REALITY!",
        Description:
          "We are here to bring your innovative imaginations to life. Our team of expert and experienced app developers will assist you throughout the planning, development, monitoring, and optimization processes. We strategize as per the nature of a project and implement the strategy by using the most relevant methodologies. A flawless performance delivery, guaranteed.",
        Points: [
          "iOS Development",
          "Android Development",
          "React native Development",
          "Flutter Development",
        ],
      },
      Web: {
        MainTitle: "WEB DEVELOPMENT",
        // SubHeading: "YOUR WEBSITE IS YOUR BEST MARKETING TOOL.",
        SubHeading: "WEBSITE, THE BEST MARKETING TOOL",
        Description:
          "We blend imagination with technology to develop the best possible website for your business. Our team of skilled developers utilizes the most advanced technologies to build websites that are well designed and deliver striking performance and user engagement.",
        Points: [
          "React/Gatsby",
          "Vue.js",
          "Python/Django",
          "Node",
          "E-Commerce Web Development",
        ],
      },
      UIUX: {
        MainTitle: "DESIGN & UI/UX",
        // SubHeading:
        // "WE TRANSFORM USER’S DIGITAL EXPERIENCE USING HUMAN PSYCHOLOGY AND BEHAVIOURAL SCIENCE.",
        SubHeading: "TRANSFORM USER EXPERIENCES",
        Description: [
          // enter p1
          "Our UI/UX designers turn your imagination into the final product. A good web design is one that guides the user towards its goal, makes the journey easy and memorable and our team provides exactly that by designing mobile apps and software customized to meet your objectives.",
          // enter p2
          "We take organizations to the next level by designing and building platforms and digital experiences that go to the crux of your organization’s needs.",
          // enter p3
          "Whether it is a SaaS product, a dashboard, or a social networking app you require — we’ve got your back.",
        ],
        Points: [
          "Logo and Branding",
          "User Experience",
          "Information Architecture",
          "Prototyping",
          "Mobile and Web Design",
        ],
      },
      GrowthMarketing: {
        MainTitle: "GROWTH MARKETING",
        // SubHeading: "WE HELP BUSINESSES GROW 10X WITH GROWTH MARKETING",

        SubHeading: "GROW 10x WITH GROWTH MARKETING",
        Description: [
          "We take care of growing your business through Paid Media & Social Media so that you can take care of your business operations.",
          "We have 5 years of experience growing businesses across Europe. Our Growth Hacking team is packed with experts in Paid Ads, Influencer Marketing and Social Media. Our costs are affordable and our results are amazing.",
        ],
        Points: [
          "Marketing Automation & Lifecycle Campaigns",
          "Analytics & Insights",
          "Central Data Management",
          "Attribution Tracking & Optimizations",
          "A/B Testing & Workflow Optimizations",
        ],
      },
      DigitalMarketing: {
        MainTitle: "DIGITAL MARKETING",
        Description: [
          "Our team of Digital Marketers is itching to create marketing strategies that will skyrocket your revenue. We maintain and grow your brand by taking care of its digital marketing. Be it graphic designing, content creation, social media strategy, or Email marketing -- We’ve got you covered.",
          "One thing each of our team members has is their thirst for knowledge. And one thing that separates them from other digital marketers is that we have the skill to craft a listening brand right from the scratch.",
        ],
        Points: [
          "SEO & ASO",
          "Paid Media Planning & Implementation",
          "Campaign POI Monitoring & Reporting",
        ],
      },
      NextGen: {
        MainTitle: "NEXT-GEN TECH SPECIALTIES",
        // SubHeading: "INTRODUCING THE TECHNOLOGY OF TOMORROW... TODAY",
        SubHeading: "INTRODUCING THE TECHNOLOGY OF TOMMORROW ",
        Description: [
          "Gimmick or next big thing? Sometimes it’s a fine line. We love experimenting with emerging tech, which ensures our stack is future-proofed and when something moves from toy to transformative, we have a nice head start.",
        ],
        Points: [
          "AR/VR",
          "Connected Hardware",
          "Blockchain/dApps",
          "Chatbots",
          "Alexa/Google Home",
        ],
      },
      TestimonialsSectionTitle: "Don't take our word for it",
      testimonial_data: [
        {
          testimonial:
            "“When our own skills did not manage to get us where we wanted, Gap Dynamics took care of the rest. The expertise, customer service and follow up we experienced from Gap Dynamics were simply flawless.”",
          author: "Andrew Mark, Product Manager",
          link: "",
          bg: "dark-blue",
        },
        {
          testimonial:
            "“Gap Dynamics team members proved to be extremely flexible and responsive. They stayed in daily contact with us, which allowed us to adjust the scope of works promptly and implement new requirements on the fly. Additionally, the team delivered demos every other week so that we could be sure that the invoicing system aligned with our business needs.”",
          author: "Sajid Malik, Associate Director,",
          link: "LS",
          bg: "red",
        },
        {
          testimonial:
            "“We have worked with Gap Dynamics on various projects, and find that they provide quality service and expertise for our programming needs. It is rare to find a service provider with such professional consistency - they are a valued service provider to our business!”",
          author: "Hammad, Ecommerce Product Owner",
          link: "ZF",
          bg: "purple",
        },
        {
          testimonial:
            "“Gap Dynamics has been supporting our business for the past 9 months in both the creation and implementation of new and tailored software. We have worked with several of their developers and cannot speak highly enough of the team. They are reliable, thorough, smart, available, extremely good communicators and very friendly! We would recommend hiring Gap Dynamics to anyone looking for a highly productive and solution driven team. We plan to continue to work with them for the long term.”",
          author: "Hammad, Ecommerce Product Owner",
          link: "ZF",
          bg: "green",
        },
        {
          testimonial:
            "“GAP DYNAMICS WORKED WITH OUR IN-HOUSE IT TEAM TO CREATE A STRAIGHTFORWARD USER EXPERIENCE WITHIN A STRICT TIME FRAME. THOUGHOUT THE DEVELOPMENT PROCESS, THEY COMMUNICATED WELL AND PROVIDED EXPERIENCED ADVICE WHICH LED US TO VICTORY.”",
          author: "Hammad, Ecommerce Product Owner",
          link: "ZF",
          bg: "blue",
        },
      ],
    },
    ServicesPageUpdated: {
      MetaTitle: "Services | Gap Dynamics",
      MarqueeHeading: [
        "WE CREATE THE DIGITAL EXPERIENCES",
        "PRODUCTS, AND VENTURES OF TOMORROW",
      ],
      MainHeading: "WE’RE POWERED BY OUR INDUSTRY-LEADING PRACTICE AREAS",
      ServicesIcons: [
        "STRATEGY",
        "RESEARCH",
        "BRANDING",
        "DESIGN",
        "ENGINEERING",
        "PRODUCT GROWTH",
      ],
      ChatButtonText: "LET'S CHAT",
      Section1Heading: "LEADING THE WAY TOWARDS A MORE DECENTRALIZED WORLD",
      Section1Text: `Gap Dynamics helps startups and enterprise companies bring their
      products to the blockchain. Innovations in blockchain technology
      are propelling us toward new standards for financial and
      business systems worldwide. Our team of blockchain developers,
      product experts, and industry specialists is here to help
      forward-thinking companies stay on top of the technological
      demands of the future.`,
      Section2Heading:
        "MAKE BOLD DECISIONS THAT UNLOCK NEW FRONTIERS OF GROWTH",
      Section2Text: `We’re experts at combining customer needs, business demands, and
      technical possibilities to disrupt the status quo. Our team of
      strategists use a Design Sprint-centric methodology to identify
      opportunities, ignite innovation, and discover new digital
      ventures to drive growth outside your core business.`,
      Section3Heading:
        "REIMAGINED EXPERIENCES THAT WIN THE HEARTS OF YOUR CUSTOMERS",
      Section3Text: `The best designs not only instill confidence in your brand, but
      inspire users to download, engage, and evangelize. We use
      human-centered design and research methods to understand your
      customers and create uniquely memorable product experiences that
      they’ll love.`,
      Section4Heading: "BUILD BETTER PRODUCTS",
      Section4Text: `Our full-stack engineering team creates world class products
      built with a range of technologies, platforms, and frameworks.
      From mobile apps to websites, frontend to middleware to backend,
      architecture consulting to cybersecurity strategy—we’ve got it
      covered.`,
      Section5Heading: "SUPERCHARGE SUSTAINABLE GROWTH",
      Section5Text: `We harness the power of analytics, growth marketing, and
      experimentation to help you grow your products and your customer
      base. Our close partnerships with a range of vendors enable us
      to craft bespoke tool stacks specially optimized to generate
      revenue and customer loyalty.`,
      Section6Heading: "LEVEL UP WITH THE POWER OF AI",
      Section6Text: `We leverage cutting-edge generative AI to build better products,
      faster. From personalization, to predictive analytics, to smart
      search, we use AI to power innovative customer experiences and
      product features.`,
      Section7Heading: "WE LOVE EXPERIMENTING WITH NEXT-GENERATION TECH",
      Section7Text: `From AR/VR, to IoT, to blockchain, to connected hardware, we're
      passionate about finding creative ways to apply emerging
      technologies to real-world business challenges. We’re obsessed
      with exploring the latest and greatest, and when something moves
      from toy to transformative, our team has a head start.`,
      Section8Heading: "YOUR CYBERSECURITY SOLUTIONS PARTNER",
      Section8Text: `Gap Dynamics offer on-demand expertise combined with the nonstop
      threat detection and response required to block attacks before
      they damage your business. Optimize efficiency while minimizing
      cost, gaining the backing you need when you need us the most.
      Monitor and analyze data and trends within a single unified
      dashboard, accessing logs at any time. We help organizations
      respond to threats faster and leverage the latest and most
      sophisticated cybersecurity technology while retaining a
      well-documented audit trail.`,
      DontTakeOurWord: "DON'T TAKE OUR WORD FOR IT",
    },
    TeamPage: {
      MetaTitle:
        "About Gap Dynamics | Germany Based Software Development Company",
      Mainheading: "Many Heads, One Team!",
      Description:
        "We are a digital transformation consultancy and engineering company that delivers cutting edge solutions for global organisations and technology startups. Since 2009 we have been helping companies and established brands reimagine their business through digitalisation.",
      TeamSectionTitle: "",
      TeamSectionSubTitle: "",
      TeamViewLinkText: "Meet",

      Mission: {
        MainTitle: "OUR MISSION",
        Text:
          // "Our mission is to help enterprises accelerate adoption of new technologies, untangle complex issues that always emerge during digital evolution, and orchestrate ongoing innovation. Whether it is a consumer-oriented app or a transformative enterprise-class solution, the company leads the process from ideation and concept to delivery, and provides ongoing support through its IS360 framework.",
          "At Gap Dynamics, we define success as running a profitable organization that:",
        Mission1: "Conducts business while upholding our core values",
        Mission2:
          "Makes meaningful contributions to the clients, users, and communities that we serve ",
        Mission3: "Nurtures healthy and balanced lives for its team members",
      },
      Core: {
        MainTitle: "OUR CORE VALUES",
        MainTitle1: "DIVERSITY & INCLUSION",
        Description:
          "At Gap Dynamics, our core values reflect the fundamental beliefs and principles that are integral to our company. They guide our actions, inform decision-making, drive culture, and align the team.",
        Text:
          "We believe In hiring the most talanted people we can find, no matter who they are or where they live.",
        Text1: "Want to be part of this crew?",
        ButtonText: "JOIN OUR TEAM",
        // "Our skilled ERP developers are experts in setting up management, tracking, booking software, and uniting the rest of your business operations within a sole, easy, and custom ERP solution.",

        // Points: ["CRM", "POS", "CMS", "ERP", "CDP"],
      },
      Qoute: {
        MainTitle: "A DIGITAL TRANSFORMATION MACHINE",
        Text:
          "EVERY MORNING I DRINK 142 CUPS OF COFFEE. I WORK 4,200 HOURS A WEEK. I SPEAK 21 LANGUAGES AND I CAN TYPE 7,000 WORDS PER MINUTE.",
        Text2:
          "I AM YOUR DIGITAL TRANSFORMATION PARTNER AND MY NAME IS GAP DYNAMICS.",
        Text3:
          "The whole is greater than the sum of its parts, but the team members at Gap Dynamics are pretty notable on their own.",
      },

      Clients: {
        MainHeading: "OUR NOTABLE CLIENTS",
        Description:
          "For over 10 years, Gap Dynamics has been helping Fortune 500 companies and established brands build solid software foundations for their businesses.",
      },

      CTA: {
        // teamHeading: "MEET",
        teamsubHeading: "MEET OUR TEAM",
        // teamPara:
        //   "Everyone at Gap Dynamics is here for the same reason: we see it as our duty to improve the user experience of the world by guiding clients through impactful digital transformations.",
        Team: [
          {
            name: "Gohar Paracha",
            position: "CEO",
            img: "jhon.jpg",
            slug: "",
          },
          {
            name: "Hassan Bokhari",
            img: "jhon.jpg",
            position: "CBDO",
            slug: "",
          },
          {
            name: "Imaad Aly",
            img: "jhon.jpg",
            position: "CBDO",
            slug: "",
          },
        ],
        MainTitle: " TECHNOLOGICAL EXCELLENCE",
        Description:
          "At Gap Dynamics we help business build an intelligently designed digital future. Tell us about your idea, and we’ll offer the most fitting technological solution.",
        OneLineDescription: "",
        ButtonText: "SHOW ALL JOBS AT GAP DYNAMICS",
      },
      NoMemberFoundApiText: "Sorry, no team members are found",
    },

    TeamDetails: {
      MetaTitle: "Team Member Page | Gap Dynamics Official Website",
      BannerHeadingText: "Introducing",
      SocialHeadingText: "Social And Contact",
      AboutHeadingText: "About",
      ReadMoreButtonText: "Get to know ",
    },

    ContactPage: {
      MetaTitle: "Contact Us | Gap Dynamics Software Development Company",
      Mainheading: "WHAT'S UP?",
      ShortDescription: "",
      Description:
        "Tell us about your project. We respond to almost every inquiry within one business day. Really!",
      ContactFormHeading: "Project Info",
      NewsletterOptionText: "Add me to the Gap Dynamics Mailing List",
      FormButtonText: "SUBMIT",

      ContactFormMessages: {
        InvalidEmail: "Invalid Email Entered",
        InvalidWebsiteUrl: "Invalid URL Entered",
        InvalidPhone: "Invalid Number",
        AllFieldsRequired: "Please Enter Required Fields",
        SuccessMessage:
          "Thank you for contacting us. we really appreciate your time and interest. we will reach you back shortly.",
        ServerError: "Sorry, Server Error Occured. Please Try Again later",
      },

      ContactFormFieldPlaceHolders: {
        fullName: "Full Name *",
        Email: "Email *",
        company: "Company",
        Phone: "Phone Number",
        website: "Website",
        address: "Location",
        message: "Project Synopsis *",
      },
    },

    JobsPage: {
      MetaTitle: "Gap Dynamics | Apply For The Best Software Development Jobs",
      Mainheading: "CAREERS AT<br>GAP DYNAMICS",
      Description:
        "Attention meticulous coders, obsessive product managers, design fanatics, early adopters, and marketing magicians: Gap Dynamics is looking for experts and outliers to join our team.",
      OfficiesHeading: "Offices",
      DepartmentsHeading: "Departments",
      ApplyNowButtonText: "Apply Now",
      ExploreBtn: "EXPLORE ROLES",
      NoJobsText: "Sorry no jobs are available now",
      LifeText: "Life at Gap Dynamics",
      LifeTextp:
        "We are a best-in-class team that not only pushes boundaries and strives for excellence, but that also shows respect and appreciation for each other through collaboration, transparency, and collective responsibility.",
      ValuesText: "Values",
      ValuesTextp:
        "Our team thoroughly exemplifies our core values and actively models these values through praise, recognition, and performance evaluation. We make sure to celebrate team members who exceptionally demonstrate our values and push our whole company to be better.",
      PerksText: "Perks & Benefits",
      PerksTextp:
        "We believe that team members who feel taken care of and valued are in the best position to remain motivated and engaged. Through the perks & benefits we offer, we cultivate a healthy, supportive, and equitable work environment for our team members.",
      DepartmentAllText: "All",
      Department1: "Web Development",
      Department2: "Graphics Design",
      Department3: "DevOps",
      Department4: "Marketing",

      office1: "Pakistan",
      office2: "Germany",

      ApiError: "Sorry, there are no jobs available at this time.",
      ApiErrorShortDescription:
        "Gap Dynamics is looking for experts and outliers to join our team. if you are interested to join our team please send us your cv at: careers@gap-dynamics.com",
      FeaturedWorkTitle: "FEATURED WORK",
      OpenRoles: "OPEN ROLES",
      OpenRolesHeading: "WANT TO DESIGN THE NEXT APP?",
      OpenRolesText:
        "Gap Dynamics builds uniquely innovative products that delight users and drive value for our clients. Our team consists of the types of hackers and rule-breakers typically found at startups instead of agencies, dev shops, or design studios. Come join the Gap Dynamics team!",
    },
    JobDetailpage: {
      MetaTitle: "Job Details | Gap Dynamics Official Website",
      LocationText: "Location",
      JobDateText: "Last Updated",
      WhatWeOfferText: "What We Offer",
      ResponsibilitesHeading: "Roles and Responsibilites",
      IdealCandidateHeading: "Ideal Candidate",
      Perks: "Perks",
      ApplyBtn: "Apply Now",
      CardBoxHeading: "Apply for this job",
      CardBoxDescription: "Send us your Cv / Resume",
      CardBoxShortDescription:
        "Would you like to join the team? Submit your application",
      CardBoxButtonText: "Apply Now",
      BelowCardHeading: "Looking for something similar?      ",
      BelowCardLinkText: "View All Jobs",

      EmptyPropertyMsg: "Not Set",
      LifeText: "Life at Gap Dynamics",
      LifeTextp:
        "We are a best-in-class team that not only pushes boundaries and strives for excellence, but that also shows respect and appreciation for each other through collaboration, transparency, and collective responsibility.",
      ValuesText: "Values",
      ValuesTextp:
        "Our team thoroughly exemplifies our core values and actively models these values through praise, recognition, and performance evaluation. We make sure to celebrate team members who exceptionally demonstrate our values and push our whole company to be better.",
      PerksText: "Perks & Benefits",
      PerksTextp:
        "We believe that team members who feel taken care of and valued are in the best position to remain motivated and engaged. Through the perks & benefits we offer, we cultivate a healthy, supportive, and equitable work environment for our team members.",
    },
    JobsApplyPage: {
      MetaTitle: "Job Apply | Gap Dynamics Official Website",
      JobTitleText: "Position",
      ApplyFormHeading: "Apply for this job",
      WhatWeOfferText: "What we offer",
      ResponsibilitesHeading: "Roles & Responsibilites",
      ApplyFormSubHeading: "(*) Fill All The Required Fields",
      FormSubmitButtonText: "Submit",
      EmptyPropertyMsg: "Not Set",
      IdealCandidateHeading: "Ideal Candidate",

      JobApplyForm: {
        InvalidEmail: "Invalid Email Entered",
        InvalidWebsiteUrl: "Invalid URL Entered",
        InvalidPhone: "Invalid Number",
        AllFieldsRequired: "Please Enter Required Fields",
        SuccessMessage:
          "Great, You have successfully submitted your application for this position",
        ServerError: "Sorry, Server Error Occured. Please Try Again later",

        CvError: "Upload Your Cv/Resume",
      },

      JobApplyFormFieldsPlaceHolders: {
        FirstName: "First Name",
        LastName: "Last Name",
        Email: "Email",
        CurrentCompany: "Current Company",
        Phone: "Phone E.g +92 XXX XXXXXXX",
        WebsiteUrl: "Website or Portfolio URL",
        LinkedInUrl: "LinkedIn URL",
        Availibility: "Availibility",
        HeardAbout: "Heard About Us?",
        Cv: "Upload your Cv/Resume (Pdf)",
        CoverLetter: "Upload Cover Letter (pdf)",
      },
    },

    PrivacyPolicyPage: {
      MetaTitle: "Privacy Policy | Gap Dynamics Official Website",
      PageTitle: "Privacy Policy",
      BannerButtonText: "Read More",

      CompanyName: "GAP Dynamics® GmbH",
      Address1: " NNK Arcade 226, Bahria Springs North ",
      Address2:
        "Bahria Intellectual Village Phase 7 Bahria Town, Islamabad, Pakistan ",

      PhoneTitle: "Phone",
      Phone: "+92 (051) 874-0983",
      PhoneLink: "tel:+920518740983",

      EmailTitle: "Email",
      Email: "hello@gap-dynamics.com",
      EmailLink: "mailto:hello@gap-dynamics.com",
      EmailText: "Email",
      CEOTitle: "CEO",
      CEOName: "Gohar Paracha",
      RegisteredTypeTitle: "Commercial register",
      RegisteredType: "District court Düsseldorf (in formation)",
      MoreInfoTitle: "Further information",
      MoreInfo:
        "Our general terms and conditions can be found You can download it here.",
      Paragraph1:
        "GAP Dynamics® is a registered trademark of GAP Dynamics GmbH.        ",
      Paragraph2:
        "GAP Dynamics is not responsible for the content of external offers to which reference is made from this website.        ",
    },
  },

  gr: {
    Menu: {
      Home: "Startseite",
      Services: "Dienstleistungen",
      Projects: "Projekte",
      Team: "Über uns",
      Jobs: "Karriere",
      Contact: "Kontakt",
      Blog: "Blogs",
    },
    ProjectPage: {
      MetaTitle: "Projects | Über GAP Dynamics GmbH",
      Heading1: "",
      Heading2: " AKTUELLE PROJEKTE",
      Description:
        "Von einer der besten iOS- und Android-Anwendungen bis hin zu reibungsloser Webentwicklung. Wir bauen Produkte die erfolgreich sind. Wir skalieren Ihr Unternehmen auch auf eine andere Ebene und erfüllen Ihre Geschäfts- und Nutzeranforderungen.",
    },
    NotFoundPage: {
      MetaTitle: "404 | Über GAP Dynamics GmbH",
      MainHeading: "404 Seite nicht gefunden!",
      Description:
        "Es tut uns leid, aber wir können die von Ihnen gesuchte Seite nicht finden. Die angeforderte URL wurde auf diesem Server nicht gefunden.",
      Button: "Zurück nach Hause",
    },
    Footer: {
      OneLineDescription: "Wir freuen uns auf Sie!",
      Email: "SPRECHEN SIE UNS AN",
      mailto: "mailto:hello@gapdynamics.de",
      GermanPhoneNo: "+49(176)62775566",
      PakistanPhoneNo: "+92(051)874-0983",
      CopyRightText: "Urheberrechte",
      PrivacyPolicyPageText: "@Impressum",
      AltText: "SENDEN",
      FooterOneLineDescription: "Machen Sie Ihr Projekt zum Wachstumsmotor!",
      FooterEmail: "HELLO@GAPDYNAMICS.DE",
      establishText: "Gegründet 2009",
      digitalText: "Digitale Transformation",
      blockchainText: "Blockchain",
      webText: "Web",
      appText: "App",
      designText: "Design & UI/UX",
      marketingText: "Digitale Marketing",
      serviceText: "Dienstleistungen",
      projectsText: "Projekte",
      teamText: "Team",
      careersText: "Karriere",
      blogText: "Blogs",
      contactText: "Kontakt",
    },
    HomePage: {
      MetaTitle: "Home | Über GAP Dynamics GmbH",
      Slider1: {
        Title: ["Wir transformieren Unternehmen"],
        Description:
          "Indem wir Ideen zum Leben erwecken und durch unsere individuelle Softwareentwicklung Wachstum stimulieren. ",
        ButtonText: "Registrieren Sie sich für eine Beratung",
      },
      Slider2: {
        Title: "Wir haben die Lähmung der Wahl beendet",
        Description:
          "IT-Komplexität reduzieren und bis zu 30 Prozent der IT-Kosten einsparen ",
        ButtonText: "",
      },
      Slider3: {
        Title: "Wir haben zur Umsatzsteigerung beigetragen",
        Description:
          "Digitale Felxibilität durch hybride Enterprise Apps, PWA und WebApps ",
        ButtonText: "",
      },
      Slider4: {
        Title: "Wir konzentrieren uns auf das Gewinnen",
        Description: "Wir optimieren Unternehmen",
        ButtonText: "",
      },
      Slider5: {
        Title: "Wir haben Effizienz verordnet",
        Description: "App Entwicklung so individuell wie ihr Unternehmen",
        ButtonText: "",
      },

      Slider10: {
        Title: "Wir haben die Check-in-Schlangen gefeuert",
        Description:
          "We transform businesses by bringing imagination to life, building solutions and stimulating growth.",
        ButtonText: "REGISTER FOR CONSULTATION",
      },
      Slider12: {
        Title: "Wir machen Sie bekannt",
        Description:
          "We transform businesses by bringing imagination to life, building solutions and stimulating growth.",
        ButtonText: "REGISTER FOR CONSULTATION",
      },
      Slider13: {
        Title: "Was ist unser Geheimnis?",
        Description:
          "We transform businesses by bringing imagination to life, building solutions and stimulating growth.",
        ButtonText: "REGISTER FOR CONSULTATION",
      },
      Slider6: {
        Title: " Digitale Transformation Consulting",
        Description:
          "Prozess- und Technologie-Kompetenz für mehr Agilität im Unternehmen.",
        ButtonText: "",
      },
      Process: {
        Title: "UNSER PROZESS",
        Description:
          "Das ist unser Leitfaden für den Erfolg unserer Kunden. Es ist die Infrastruktur, die es unserem Team ermöglicht, die großartigen Dinge zu schaffen, die für unsere Kunden Ergebnisse bringen.",
      },
      Peoples: {
        Title: "UNSERE MENSCHEN",
        Description:
          "Sie kommen von Startups, nicht von Agenturen. Anstatt sich mit Auszeichnungen zu beschäftigen, haben sie sich auf die Zufriedenheit von Kunden und Nutzern konzentriert. Sie sind Ex-CEOs, Ex-CTOs, Ex-CMOs - Menschen, die wissen, wie man auf dem Markt gewinnt.",
      },
      Slider7: {
        Title: "WIE WIR ES MACHEN?",
        Description: {
          heading: "Agil und iterativ ",
          details:
            "Wir leben Agilität und bieten unseren Kunden höchste Effizienz und Mut zur Innovation. Unsere Arbeitsprozesse sind transparent und lösungsorientiert. Wir verwenden Scrum und agiles Projektmanagement zu 100%.",
        },
        ButtonText: "",
      },
      Slider8: {
        Title: "Software-Entwicklung mit digitaler Exzellenz",
        Description:
          "Optimieren Ihre Online Portale, entwicklen komplexe Webseiten und schaffen passgenaue Enterprise Systeme für eine verbesserte Webpräsenz",
        ButtonText: "",
      },
      Slider9: {
        Title: "Wir investieren in Exzellenz",
        Description: "",
        ButtonText: "",
      },
      FooterHeading1: "Wir freuen uns auf Sie!",
      FooterHeading2: "SPRECHEN SIE UNS AN",
      PrivacyPolicy: "Impressum",
      CopyRightText: "Gap Dynamics",
      establishText: "Gegründet 2009",
      digitalText: "Digitale Transformation",
      blockchainText: "Blockchain",
      webText: "Web",
      appText: "App",
      designText: "Design & UI/UX",
      marketingText: "Digitale Marketing",
      serviceText: "Dienstleistungen",
      projectsText: "Projekte",
      teamText: "Team",
      careersText: "Karriere",
      blogText: "Blogs",
      contactText: "Kontakt",
    },
    ServicesPage: {
      MetaTitle: "Dienstleistungen | Über GAP Dynamics GmbH",
      SuperScriptTitle: "NEU DEFINIEREN",
      Mainheading: "DIGITALE ERLEBNISSE ",
      Description:
        "Wir sind ein Full-Service IT-Unternehmen mit robusten Praktiken in den Bereichen Webdesign und -entwicklung, App-Design und -Entwicklung, Entwicklung von ERP-Lösungen, Branding, Digitales Marketing, SEO, Paid Media und Opportunity Acquisition. Unsere digitalen Erfahrungen sind menschenzentriert und zukunftssicher, um Ihren Umsatz langfristig zu steigern. Garantiert.",
      Points: [
        "Digitale Strategie & ROI-Planung",
        "Customer Experience & Marketing-Automatisierung",
        "Geschäftsanalyse und Data Science",
        "Steuerung, Risk & Compliance",
        "Opportunity-Analyse",
      ],
      Points_ButtonText: "ANFRAGE",
      ButtonText: "Was wir machen",
      EnterpriseApp: {
        MainTitle: "Entwicklung von Unternehmensanwendungen",
        Description: [
          "Mit ERP können Sie Ihr Geschäft nahtlos betreiben. Egal, ob Sie ein etabliertes Unternehmen oder ein Start-up sind, eine zuverlässige ERP-Lösung kann Ihre Kosten senken und gleichzeitig Ihre Mitarbeiter unterstützen.",
          "Unsere erfahrenen ERP-Entwickler sind Experten im Einrichten von Management-, Tracking- und Buchungssoftware und vereinen den Rest Ihrer Geschäftsabläufe in einer einzigen, einfachen und individuellen ERP-Lösung.",
        ],
        Points: ["CRM", "POS", "CMS", "ERP", "CDP"],
      },
      Cyber: {
        MainTitle: "CYBERSICHERHEITSDIENSTE",
        SubHeading: "IHR PARTNER FÜR CYBERSICHERHEITSLÖSUNGEN",
        Description: [
          "Cybersicherheit schützt digitalen Netzwerke, Programme, Geräte, Systeme, Server, Online-Assets und die Daten der Unternehmen. Als Managed Security Services Provider (MSSP) fungieren wir als vertrauenswürdiger Partner unserer Kunden, der über fortschrittliches Know-how in der aktuellen Bedrohungslandschaft verfügt. Lassen Sie uns zu einer Erweiterung Ihres Teams werden, ganz gleich, ob Sie Unterstützung bei der Überwachung der Netzwerksicherheit rund um die Uhr, virtuelle CISO-Dienste oder Penetrationstests benötigen.",

          "Gap Dynamics bietet On-Demand-Fachwissen in Kombination mit der ständigen Erkennung von und Reaktion auf Bedrohungen, die erforderlich sind, um Angriffe abzuwehren, bevor sie Ihrem Unternehmen schaden. Überwachen und analysieren Sie Daten und Trends in einem einzigen, einheitlichen Dashboard und greifen Sie jederzeit auf Protokolle zu. Wir helfen Unternehmen, schneller auf Bedrohungen zu reagieren und die neueste und ausgefeilteste Cybersicherheitstechnologie zu nutzen, während gleichzeitig ein gut dokumentierter Audit-Trail erhalten bleibt. Optimieren Sie die Effizienz bei gleichzeitiger Minimierung der Kosten und erhalten Sie die Unterstützung, die Sie brauchen, wenn Sie uns am meisten brauchen.",
        ],
        Points: [
          "Anwendungssicherheit",
          "Sicherheit der Netzinfrastruktur",
          "Red Teaming",
          "Social Engineering-Tests",
          "WLAN Penetration Tests",
        ],
      },
      App: {
        MainTitle: "App-Entwicklung",
        SubHeading: "Sie haben eine Idee? Machen Sie Diese zur Realität",
        Description:
          " Wir sind dafür da, Ihre innovativen Vorstellungen zum Leben zu erwecken. Unser Team aus kompetenten und erfahrenen App-Entwicklern unterstützt Sie während des gesamten Planungs-, Entwicklungs-, Überwachungs- und Optimierungsprozesses. Wir entwickeln eine Strategie entsprechend des Projektes und setzen diese mit den relevantesten Methoden um. Eine einwandfreie Leistungserbringung, garantiert!",
        Points: [
          "iOS-Entwicklung",
          "Android-Entwicklung",
          "React-Native-Entwicklung",
          "Flutter-Entwicklung",
        ],
      },

      Web: {
        MainTitle: "Web Entwicklung",
        SubHeading: "Ihre Website ist Ihr bestes Marketinginstrument",
        Description:
          "Wir verbinden Vorstellungskraft mit Technologie, um die bestmögliche Website für Ihr Unternehmen zu entwickeln. Unser Team von qualifizierten Entwicklern nutzt die fortschrittlichsten Technologien, um Webseiten zu erstellen, die gut gestaltet sind und eine beeindruckende Leistung und Benutzerbindung bieten.",
        Points: [
          "React.js/Gatsby",
          "Vue.js",
          "Python/Django",
          "Node.js",
          "Ecommerce-Webentwicklung",
        ],
      },
      UIUX: {
        MainTitle: "DESIGN & UI/UX",
        SubHeading:
          "Digitale Erfahrung der Benutzer mit Hilfe von Psychologie und Verhaltenswissenschaft",
        Description: [
          " Unsere UI/UX-Designer setzen Ihre Vorstellungen in das Endprodukt um. Ein gutes Webdesign ist eines, das den Benutzer zum Ziel führt,die Reise einfach und einprägsam macht und unser Team bietet genau das, indem es mobile Apps und Software entwirft, die auf Ihre Ziele  zugeschnitten sind.",
          "Wir bringen Organisationen auf die nächste Stufe, indem wir Plattformen und digitale Erlebnisse entwerfen und bauen, die den Kern der Bedürfnisse Ihrer Organisation treffen.",
          "Ob es sich um ein SaaS-Produkt, ein Dashboard oder eine App für soziale Netzwerke handelt - wir halten Ihnen den Rücken frei.",
        ],
        Points: [
          "Logo und Branding",
          "Benutzererfahrung",
          "Informationsarchitektur",
          "Prototyp entwickeln",
          "Mobil- und Webdesign",
        ],
      },
      Blockchain: {
        MainTitle: "BLOCKCHAIN-ENTWICKLUNG",
        SubHeading: "WEGBEREITER FÜR EINE DEZENTRALERE WELT",
        Description: [
          "Gap Dynamics unterstützt Startups und etablierte Unternehmen dabei, ihre Produkte auf die Blockchain zu bringen. Innovationen in der Blockchain-Technologie treiben uns zu neuen Standards für Finanz- und Geschäftssysteme weltweit. Unser Team aus Blockchain-Entwicklern, Produktexperten und Branchenspezialisten unterstützt zukunftsorientierte Unternehmen mit den technologischen Anforderungen der Zukunft Schritt zu halten.",

          "Gap Dynamics bietet maßgeschneiderte Blockchain- und Web 3.0-Entwicklungsdienste für E-Commerce Lösungen und mobile Anwendungen. Gap Dynamics bietet eine von maßgeschneiderten, kundenspezifischen und innovativen Blockchain-Entwicklungslösungen. Ob NFTs, DeFi, DEX-Aggregatoren.",
        ],
        Points: [
          "Blockchain Consulting",
          "Public und private Blockchain Entwicklung",
          "DApp Design & Entwicklung",
          "Kryptowährungsberatung Consulting & Wallet-Entwicklung",
          "Blockchain Auditing und Sicherheitsprüfung",
        ],
      },
      GrowthMarketing: {
        MainTitle: "Wachstumsmarketing",
        SubHeading:
          "Wir helfen Unternehmen mit Growth Marketing 10x zu wachsen",
        Description: [
          " Wir kümmern uns um das Wachstum Ihres Unternehmens durch Paid Media & Social Media, damit Sie sich um Ihr operatives Geschäft kümmern können",

          "  Wir haben 12 Jahre Erfahrung im Unternehmenswachstum in ganz Europa. Unser Growth Hacking Team besteht aus Experten für Bezahlte Werbung, Influencer, Marketing und Social Media. Unsere Kosten sind erschwinglich und unsere Ergebnisse sind erstaunlich.",
        ],
        Points: [
          "Marketing Automation & Lifecycle-Kampagnen",
          "Analysen und Einblicke",
          "Zentrale Datenverwaltung",
          "Attributionsverfolgung und Optimierungen",
          "A/B-Tests & Workflow-Optimierungen",
        ],
      },
      DigitalMarketing: {
        MainTitle: "Digitales Marketing",
        Description: [
          "    Unser Digital Marketing Team ist darauf bedacht, Marketingstrategien zu entwickeln, die Ihren Umsatz steigern lassen. Wir pflegen und erweitern Ihre Marke, indem wir uns um ihr digitales Marketing kümmern. Ob Grafikdesign, Content-Erstellung,Social-Media-Strategie oder E-Mail-Marketing - wir haben alles für Sie.",
          " Eine Sache, die jedes unserer Teammitglieder hat, ist ihr Wissensdurst. Und eine Sache, die sie von anderen digitalen Vermarklern unterscheidet, ist, dass wir die Fähigkeit haben, eine Marke von Grund auf neu zu gestalten.",
        ],
        Points: [
          "SEO & ASO",
          "Paid Media Planung & Implementierung",
          "Kampagnen - POI - Überwachung und -Berichte",
        ],
      },
      NextGen: {
        MainTitle: "TECHNISCHE SPEZIALITÄTEN",
        SubHeading: "Einführung in die Technologie von morgen... Heute",
        Description: [
          "Mit der sich ständig verändernden Welt der Technologie kommt die Nachfrage nach futuristischen Produkten und Gap Dynamics ist stolz darauf, seinen Kunden zum Erfolg zu verhelfen, indem wir zeitlose Lösungen entwickeln, die innovativ, flexibel und skalierbar sind.",
          "Wir haben jahrelange Erfahrung in der Entwicklung von Lösungen und Produkten in Branchen wie Telekommunikation, Solarenergie, Banken, Gesundheitswesen, Versicherungen, Einzelhandel und Fertigung.",
        ],
        Points: [
          "AR/VR",
          "Vernetzte Hardware",
          "Blockchain/dApps",
          "Chatbots",
          "Alexa/Google Home",
        ],
      },
      testimonial_data: [
        {
          testimonial:
            "“Durch die Power und Flexibilität virtueller Teams bieten wir als Full Service Digitalagentur aus Düsseldorf Projekte für alle Plattformen, Kanäle und Medien”",
          author: "Andrew Mark, Product Manager",
          link: "",
          bg: "light-yellow",
        },
        {
          testimonial:
            "“Ob Web-Projekt, Website Relaunch, Mobile & Web App Projekt, Design oder Performance Marketing”",
          author: "Sajid Malik, Associate Director,",
          link: "LS",
          bg: "purple",
        },
        {
          testimonial:
            "“wir konzentrieren uns auf die beste Lösung, damit Sie Ihre Ziele erreichen.”",
          author: "Hammad, Ecommerce Product Owner",
          link: "ZF",
          bg: "blue",
        },
      ],
    },
    ServicesCopyPage: {
      MetaTitle: "Dienstleistungen | Über GAP Dynamics GmbH",
      SuperScriptTitle: "NEU DEFINIEREN",
      Mainheading: "DIGITALE ERLEBNISSE ",
      Description:
        "Wir sind ein Full-Service IT-Unternehmen mit robusten Praktiken in den Bereichen Webdesign und -entwicklung, App-Design und -Entwicklung, Entwicklung von ERP-Lösungen, Branding, Digitales Marketing, SEO, Paid Media und Opportunity Acquisition. Unsere digitalen Erfahrungen sind menschenzentriert und zukunftssicher, um Ihren Umsatz langfristig zu steigern. Garantiert.",
      Points: [
        "Digitale Strategie & ROI-Planung",
        "Customer Experience & Marketing-Automatisierung",
        "Geschäftsanalyse und Data Science",
        "Steuerung, Risk & Compliance",
        "Opportunity-Analyse",
      ],
      Points_ButtonText: "ANFRAGE",
      ButtonText: "Was wir machen",
      EnterpriseApp: {
        MainTitle: "Entwicklung von Unternehmensanwendungen",
        Description: [
          "Mit ERP können Sie Ihr Geschäft nahtlos betreiben. Egal, ob Sie ein etabliertes Unternehmen oder ein Start-up sind, eine zuverlässige ERP-Lösung kann Ihre Kosten senken und gleichzeitig Ihre Mitarbeiter unterstützen.",
          "Unsere erfahrenen ERP-Entwickler sind Experten im Einrichten von Management-, Tracking- und Buchungssoftware und vereinen den Rest Ihrer Geschäftsabläufe in einer einzigen, einfachen und individuellen ERP-Lösung.",
        ],
        Points: ["CRM", "POS", "CMS", "ERP", "CDP"],
      },
      Cyber: {
        MainTitle: "CYBERSICHERHEITSDIENSTE",
        SubHeading: "IHR PARTNER FÜR CYBERSICHERHEITSLÖSUNGEN",
        Description: [
          "Cybersicherheit schützt digitalen Netzwerke, Programme, Geräte, Systeme, Server, Online-Assets und die Daten der Unternehmen. Als Managed Security Services Provider (MSSP) fungieren wir als vertrauenswürdiger Partner unserer Kunden, der über fortschrittliches Know-how in der aktuellen Bedrohungslandschaft verfügt. Lassen Sie uns zu einer Erweiterung Ihres Teams werden, ganz gleich, ob Sie Unterstützung bei der Überwachung der Netzwerksicherheit rund um die Uhr, virtuelle CISO-Dienste oder Penetrationstests benötigen.",

          "Gap Dynamics bietet On-Demand-Fachwissen in Kombination mit der ständigen Erkennung von und Reaktion auf Bedrohungen, die erforderlich sind, um Angriffe abzuwehren, bevor sie Ihrem Unternehmen schaden. Überwachen und analysieren Sie Daten und Trends in einem einzigen, einheitlichen Dashboard und greifen Sie jederzeit auf Protokolle zu. Wir helfen Unternehmen, schneller auf Bedrohungen zu reagieren und die neueste und ausgefeilteste Cybersicherheitstechnologie zu nutzen, während gleichzeitig ein gut dokumentierter Audit-Trail erhalten bleibt. Optimieren Sie die Effizienz bei gleichzeitiger Minimierung der Kosten und erhalten Sie die Unterstützung, die Sie brauchen, wenn Sie uns am meisten brauchen.",
        ],
        Points: [
          "Anwendungssicherheit",
          "Sicherheit der Netzinfrastruktur",
          "Red Teaming",
          "Social Engineering-Tests",
          "WLAN Penetration Tests",
        ],
      },
      App: {
        MainTitle: "App-Entwicklung",
        SubHeading: "Sie haben eine Idee? Machen Sie Diese zur Realität",
        Description:
          " Wir sind dafür da, Ihre innovativen Vorstellungen zum Leben zu erwecken. Unser Team aus kompetenten und erfahrenen App-Entwicklern unterstützt Sie während des gesamten Planungs-, Entwicklungs-, Überwachungs- und Optimierungsprozesses. Wir entwickeln eine Strategie entsprechend des Projektes und setzen diese mit den relevantesten Methoden um. Eine einwandfreie Leistungserbringung, garantiert!",
        Points: [
          "iOS-Entwicklung",
          "Android-Entwicklung",
          "React-Native-Entwicklung",
          "Flutter-Entwicklung",
        ],
      },

      Web: {
        MainTitle: "Web Entwicklung",
        SubHeading: "Ihre Website ist Ihr bestes Marketinginstrument",
        Description:
          "Wir verbinden Vorstellungskraft mit Technologie, um die bestmögliche Website für Ihr Unternehmen zu entwickeln. Unser Team von qualifizierten Entwicklern nutzt die fortschrittlichsten Technologien, um Webseiten zu erstellen, die gut gestaltet sind und eine beeindruckende Leistung und Benutzerbindung bieten.",
        Points: [
          "React.js/Gatsby",
          "Vue.js",
          "Python/Django",
          "Node.js",
          "Ecommerce-Webentwicklung",
        ],
      },
      UIUX: {
        MainTitle: "DESIGN & UI/UX",
        SubHeading:
          "Digitale Erfahrung der Benutzer mit Hilfe von Psychologie und Verhaltenswissenschaft",
        Description: [
          " Unsere UI/UX-Designer setzen Ihre Vorstellungen in das Endprodukt um. Ein gutes Webdesign ist eines, das den Benutzer zum Ziel führt,die Reise einfach und einprägsam macht und unser Team bietet genau das, indem es mobile Apps und Software entwirft, die auf Ihre Ziele  zugeschnitten sind.",
          "Wir bringen Organisationen auf die nächste Stufe, indem wir Plattformen und digitale Erlebnisse entwerfen und bauen, die den Kern der Bedürfnisse Ihrer Organisation treffen.",
          "Ob es sich um ein SaaS-Produkt, ein Dashboard oder eine App für soziale Netzwerke handelt - wir halten Ihnen den Rücken frei.",
        ],
        Points: [
          "Logo und Branding",
          "Benutzererfahrung",
          "Informationsarchitektur",
          "Prototyp entwickeln",
          "Mobil- und Webdesign",
        ],
      },
      Blockchain: {
        MainTitle: "BLOCKCHAIN-ENTWICKLUNG",
        SubHeading: "WEGBEREITER FÜR EINE DEZENTRALERE WELT",
        Description: [
          "Gap Dynamics unterstützt Startups und etablierte Unternehmen dabei, ihre Produkte auf die Blockchain zu bringen. Innovationen in der Blockchain-Technologie treiben uns zu neuen Standards für Finanz- und Geschäftssysteme weltweit. Unser Team aus Blockchain-Entwicklern, Produktexperten und Branchenspezialisten unterstützt zukunftsorientierte Unternehmen mit den technologischen Anforderungen der Zukunft Schritt zu halten.",

          "Gap Dynamics bietet maßgeschneiderte Blockchain- und Web 3.0-Entwicklungsdienste für E-Commerce Lösungen und mobile Anwendungen. Gap Dynamics bietet eine von maßgeschneiderten, kundenspezifischen und innovativen Blockchain-Entwicklungslösungen. Ob NFTs, DeFi, DEX-Aggregatoren.",
        ],
        Points: [
          "Blockchain Consulting",
          "Public und private Blockchain Entwicklung",
          "DApp Design & Entwicklung",
          "Kryptowährungsberatung Consulting & Wallet-Entwicklung",
          "Blockchain Auditing und Sicherheitsprüfung",
        ],
      },
      GrowthMarketing: {
        MainTitle: "Wachstumsmarketing",
        SubHeading:
          "Wir helfen Unternehmen mit Growth Marketing 10x zu wachsen",
        Description: [
          " Wir kümmern uns um das Wachstum Ihres Unternehmens durch Paid Media & Social Media, damit Sie sich um Ihr operatives Geschäft kümmern können",

          "  Wir haben 12 Jahre Erfahrung im Unternehmenswachstum in ganz Europa. Unser Growth Hacking Team besteht aus Experten für Bezahlte Werbung, Influencer, Marketing und Social Media. Unsere Kosten sind erschwinglich und unsere Ergebnisse sind erstaunlich.",
        ],
        Points: [
          "Marketing Automation & Lifecycle-Kampagnen",
          "Analysen und Einblicke",
          "Zentrale Datenverwaltung",
          "Attributionsverfolgung und Optimierungen",
          "A/B-Tests & Workflow-Optimierungen",
        ],
      },
      DigitalMarketing: {
        MainTitle: "Digitales Marketing",
        Description: [
          "    Unser Digital Marketing Team ist darauf bedacht, Marketingstrategien zu entwickeln, die Ihren Umsatz steigern lassen. Wir pflegen und erweitern Ihre Marke, indem wir uns um ihr digitales Marketing kümmern. Ob Grafikdesign, Content-Erstellung,Social-Media-Strategie oder E-Mail-Marketing - wir haben alles für Sie.",
          " Eine Sache, die jedes unserer Teammitglieder hat, ist ihr Wissensdurst. Und eine Sache, die sie von anderen digitalen Vermarklern unterscheidet, ist, dass wir die Fähigkeit haben, eine Marke von Grund auf neu zu gestalten.",
        ],
        Points: [
          "SEO & ASO",
          "Paid Media Planung & Implementierung",
          "Kampagnen - POI - Überwachung und -Berichte",
        ],
      },
      NextGen: {
        MainTitle: "TECHNISCHE SPEZIALITÄTEN",
        SubHeading: "Einführung in die Technologie von morgen... Heute",
        Description: [
          "Mit der sich ständig verändernden Welt der Technologie kommt die Nachfrage nach futuristischen Produkten und Gap Dynamics ist stolz darauf, seinen Kunden zum Erfolg zu verhelfen, indem wir zeitlose Lösungen entwickeln, die innovativ, flexibel und skalierbar sind.",
          "Wir haben jahrelange Erfahrung in der Entwicklung von Lösungen und Produkten in Branchen wie Telekommunikation, Solarenergie, Banken, Gesundheitswesen, Versicherungen, Einzelhandel und Fertigung.",
        ],
        Points: [
          "AR/VR",
          "Vernetzte Hardware",
          "Blockchain/dApps",
          "Chatbots",
          "Alexa/Google Home",
        ],
      },
      testimonial_data: [
        {
          testimonial:
            "“Durch die Power und Flexibilität virtueller Teams bieten wir als Full Service Digitalagentur aus Düsseldorf Projekte für alle Plattformen, Kanäle und Medien”",
          author: "Andrew Mark, Product Manager",
          link: "",
          bg: "light-yellow",
        },
        {
          testimonial:
            "“Ob Web-Projekt, Website Relaunch, Mobile & Web App Projekt, Design oder Performance Marketing”",
          author: "Sajid Malik, Associate Director,",
          link: "LS",
          bg: "purple",
        },
        {
          testimonial:
            "“wir konzentrieren uns auf die beste Lösung, damit Sie Ihre Ziele erreichen.”",
          author: "Hammad, Ecommerce Product Owner",
          link: "ZF",
          bg: "blue",
        },
      ],
    },
    ServicesPageUpdated: {
      MetaTitle: "Dienstleistungen | Gap Dynamics",
      MarqueeHeading: [
        "WIR SCHAFFEN DIE DIGITALEN ERLEBNISSE,",
        "PRODUKTE UND UNTERNEHMUNGEN VON MORGEN",
      ],
      MainHeading:
        "WIR WERDEN VON UNSEREN BRANCHENFÜHRENDEN PRAXISBEREICHEN UNTERSTÜTZT",
      ServicesIcons: [
        "STRATEGIE",
        "FORSCHUNG",
        "BRANDING",
        "DESIGN",
        "ENGINEERING",
        "PRODUKTWACHSTUM",
      ],
      ChatButtonText: "LET'S CHAT",
      Section1Heading: "WEGBEREITER FÜR EINE DEZENTRALERE WELT",
      Section1Text: `Gap Dynamics unterstützt Startups und Unternehmen dabei, ihre Produkte auf die Blockchain zu bringen. Innovationen in der Blockchain-Technologie treiben uns zu neuen Standards für Finanz- und Geschäftssysteme weltweit. Unser Team aus Blockchain-Entwicklern, Produktexperten und Branchenspezialisten unterstützt zukunftsorientierte Unternehmen dabei, mit den technologischen Anforderungen der Zukunft Schritt zu halten.`,
      Section2Heading:
        "MUTIGE ENTSCHEIDUNGEN ZU TREFFEN, DIE NEUE WACHSTUMSGRENZEN ERSCHLIESSEN",
      Section2Text: `Wir sind Experten darin, Kundenbedürfnisse, geschäftliche Anforderungen und technische Möglichkeiten zu kombinieren, um den Status quo zu verändern. Unser Team von Strategen nutzt eine Design Sprint-zentrierte Methodik, um Chancen zu identifizieren, Innovationen zu entfachen und neue digitale Unternehmungen zu entdecken, die das Wachstum außerhalb Ihres Kerngeschäfts vorantreiben.`,
      Section3Heading:
        "NEU GESTALTETE ERLEBNISSE, DIE DIE HERZEN IHRER KUNDEN GEWINNEN",
      Section3Text: `Die besten Designs wecken nicht nur das Vertrauen in Ihre Marke, sondern inspirieren die Nutzer zum Herunterladen, zum Engagement und zur Evangelisierung. Wir nutzen menschenzentriertes Design und Forschungsmethoden, um Ihre Kunden zu verstehen und einzigartige, unvergessliche Produkterlebnisse zu schaffen, die sie lieben werden.`,
      Section4Heading: "BESSERE PRODUKTE ENTWICKELN",
      Section4Text: `Unser Full-Stack-Engineering-Team entwickelt Produkte von Weltklasse die mit einer Reihe von Technologien, Plattformen und Frameworks erstellt werden. Von mobilen Anwendungen bis hin zu Websites, Frontend, Middleware und Backend, Architekturberatung bis hin zu Cybersicherheitsstrategien - wir haben alles abgedeckt.`,
      Section5Heading: "NACHHALTIGES WACHSTUM ZU STEIGERN",
      Section5Text: `Wir nutzen die Macht der Analyse, des Wachstumsmarketings und des Experimentierens, um Sie beim Wachstum Ihrer Produkte und Ihres Kundenstamms zu unterstützen. Unsere engen Partnerschaften mit einer Reihe von Anbietern ermöglichen es uns, maßgeschneiderte Tool-Stacks zu entwickeln, die speziell für die Generierung von Umsatz und Kundentreue optimiert sind.`,
      Section6Heading: "AUFSTEIGEN MIT DER KRAFT DER KI",
      Section6Text: `Wir setzen modernste generative KI ein, um schneller bessere Produkte zu entwickeln. Von Personalisierung über prädiktive Analysen bis hin zu intelligenter Suche nutzen wir KI, um innovative Kundenerlebnisse und Produktfunktionen zu ermöglichen.`,
      Section7Heading:
        "WIR LIEBEN ES, MIT TECHNOLOGIEN DER NÄCHSTEN GENERATION ZU EXPERIMENTIEREN",
      Section7Text: `Von AR/VR über IoT und Blockchain bis hin zu vernetzter Hardware - wir suchen mit Leidenschaft nach kreativen Wegen, um neue Technologien auf reale geschäftliche Herausforderungen anzuwenden. Wir sind besessen davon, das Neueste und Beste zu erforschen, und wenn etwas vom Spielzeug zum Transformator wird, hat unser Team einen Vorsprung.`,
      Section8Heading: "IHR PARTNER FÜR CYBERSICHERHEITSLÖSUNGEN",
      Section8Text: `Gap Dynamics bietet On-Demand-Fachwissen in Kombination mit der ununterbrochenen Erkennung von und Reaktion auf Bedrohungen, die erforderlich sind, um Angriffe abzuwehren, bevor sie Ihrem Unternehmen schaden. Optimieren Sie die Effizienz bei gleichzeitiger Minimierung der Kosten und erhalten Sie die Unterstützung, die Sie brauchen, wenn Sie uns am meisten brauchen. Überwachen und analysieren Sie Daten und Trends in einem einzigen, einheitlichen Dashboard und greifen Sie jederzeit auf Protokolle zu. Wir helfen Unternehmen, schneller auf Bedrohungen zu reagieren und die neueste und ausgefeilteste Cybersicherheitstechnologie zu nutzen, während gleichzeitig ein gut dokumentierter Prüfpfad erhalten bleibt.`,
      DontTakeOurWord: "GLAUBEN SIE NICHT AN UNSER WORT",
    },
    TeamPage: {
      MetaTitle: "Über uns | Über GAP Dynamics GmbH",
      Mainheading: "Viele Köpfe. Ein Team.",
      Description:
        "Wir sind ein Beratungs- und Entwicklungsunternehmen für die digitale Transformation, das innovative Lösungen für globale Organisationen und Technologie-Start-ups anbietet. Seit 2006 helfen wir Unternehmen und etablierte Marken, ihr Geschäft durch Digitalisierung neu zu gestalten.",
      TeamSectionTitle: "EINFÜHRUNG UNTERNEHMENSFÜHRUNG",
      TeamSectionSubTitle: "",
      TeamViewLinkText: "Über",

      Mission: {
        MainTitle: "UNSERE MISSION",
        Text: "",
        Mission1: "Hinter allen großen Errungenschaften steht ein Team. ",
        Mission2:
          "Von Medizin und Telekommunikation über Katastrophenhilfe bis hin zum Pizzaservice helfen unsere Produkte Teams in aller Welt, die Menschheit durch Software voranzubringen.",
        Mission3:
          "Unsere Mission ist es, das volle Potenzial eines jeden Teams zu entfalten.",
      },
      Core: {
        MainTitle: "UNSERE GRUNDWERTE",
        MainTitle1: "VIELFALT UND HETROGENITÄT",
        Description:
          "Die Grundwerte von Gap Dynamics spiegeln die grundlegenden Überzeugungen und Prinzipien wider, die für unser Unternehmen wesentlich sind. Sie leiten unser Handeln, beeinflussen die Entscheidungsfindung, treiben die Kultur voran und richten das Team aus.",
        Text:
          "Wir stellen die talentiertesten Leute ein, die wir finden können, unabhängig davon, wer sie sind oder wo sie leben.",
        Text1: "Möchten Sie zu dieser Crew gehören?",
        ButtonText: "TEAM BEITRETEN",
        // "Our skilled ERP developers are experts in setting up management, tracking, booking software, and uniting the rest of your business operations within a sole, easy, and custom ERP solution.",

        // Points: ["CRM", "POS", "CMS", "ERP", "CDP"],
      },

      Clients: {
        MainHeading: "UNSERE BEMERKENSWERTE KUNDEN",
        Description:
          "Seit über 10 Jahren unterstützt Gap Dynamics Unternehmen und etablierte Marken beim Aufbau einer soliden Softwarebasis für ihr Geschäft.",
      },

      Qoute: {
        MainTitle: "EINE DIGITALE TRANSFORMATIONSMASCHINE",
        Text:
          "ICH TRINKE JEDEN MORGEN 142 TASSEN KAFFEE. ICH ARBEITE 4.200 STUNDEN PRO WOCHE. ICH SPRECHE 21 SPRACHEN UND KANN 7.000 WÖRTER PRO MINUTE TIPPEN.",
      },
      CTA: {
        teamHeading: "",
        teamsubHeading: "UNSER TEAM KENNENLERNEN",
        Team: [
          {
            name: "Gohar Paracha",
            position: "Geschäftsleitung",
            img: "jhon.jpg",
            slug: "gohar-parcha",
          },
          {
            name: "Hassan Bokhari",
            img: "jhon.jpg",
            position: "CBDO",
            slug: "hassan-bokhari",
          },
          {
            name: "Imaad Aly",
            img: "jhon.jpg",
            position: "CBDO",
            slug: "imaad-aly",
          },
        ],
        MainTitle: "TECHNOLOGIE-EXZELLENZ",
        Description:
          "Du denkst, du hast das Zeug zum Team? Schaue dir unsere aktuelle Stellenangebote an und bewerben dich!",
        OneLineDescription: "",
        ButtonText: "ALLE JOBS BEI GAP DYNAMICS ANZEIGEN",
      },
      NoMemberFoundApiText: "Es wurden leider keine Teammitglieder gefunden",
    },

    TeamDetails: {
      MetaTitle: "Seite für Teammitglieder | Über GaAP Dynamics GmbH",
      BannerHeadingText: "Einführung",
      SocialHeadingText: "Soziales und Kontakt",
      AboutHeadingText: "Über",
      ReadMoreButtonText: "Weiterlesen",
    },

    ContactPage: {
      MetaTitle: "Kontakt | Über die Gap Dynamics GmbH",
      Mainheading: "HALLO!",
      ShortDescription: "Wie können wir dir helfen?",
      Description:
        "Erzählen Sie uns von Ihrem Projekt. Wir beantworten fast jede Anfrage innerhalb eines Werktages. Ja wirklich!",
      ContactFormHeading: "Projekt info",
      NewsletterOptionText: "Füge mich zur GAPs Mailingliste hinzu",
      FormButtonText: "Senden",

      ContactFormMessages: {
        InvalidEmail: "Bitte eine gültige E-Mail-Adresse eintragen",
        InvalidWebsiteUrl: "Ungültige URL eingegeben",
        InvalidPhone: "Ungültige Nummer",
        AllFieldsRequired: "Bitte Pflichtfelder eingeben",
        SuccessMessage:
          "Danke, dass Sie uns kontaktiert haben. Wir schätzen Ihre Zeit und Ihr Interesse sehr. wir werden Sie in Kürze wieder erreichen.",
        ServerError:
          "Es ist ein Serverfehler aufgetreten. Bitte versuchen Sie es später erneut",
      },

      ContactFormFieldPlaceHolders: {
        fullName: "Vollständiger Name *",
        Email: "Email *",
        company: "Unternehmen",
        Phone: "Telefon +4917662775566",
        website: "Webseite",
        address: "Stadt Land",
        message: "Deine Nachricht *",
      },
    },

    JobsPage: {
      MetaTitle: "Karriere | Über GAP Dynamics GmbH",
      Mainheading: "JOBS BEI GAP DYNAMICS",
      Description:
        "Akribische Programmierer, obsessive Produktmanager, Designfanatiker, Early Adopters und Marketingmagier aufgepasst: Gap Dynamics sucht Experten und kluge Köpfe für unser Team.",
      OfficiesHeading: "Büros",
      DepartmentsHeading: "Abteilungen",
      ApplyNowButtonText: "Jetzt bewerben",
      ExploreBtn: "ROLLEN ERFORSCHEN",
      NoJobsText: "Leider sind derzeit keine Jobs verfügbar",
      LifeText: " Life at Gap Dynamics",
      LifeTextp:
        "Wir sind ein junges Team, das nicht nur Grenzen überschreitet und nach Spitzenleistungen strebt, sondern auch durch Zusammenarbeit, Transparenz und gemeinsame Verantwortung Respekt und Wertschätzung füreinander zeigt.",
      ValuesText: "Werte",
      ValuesTextp:
        "Unser Team lebt unsere Grundwerte durch Lob, Anerkennung und Leistungsbewertung aktiv vor. Wir achten darauf, Teammitglieder zu feiern, die unsere Werte in herausragender Weise vorleben und unser gesamtes Unternehmen dazu anspornen, besser zu werden.",
      PerksText: "Vergünstigungen & Vorteile",
      PerksTextp:
        "Wir sind davon überzeugt, dass Teammitglieder, die sich gut aufgehoben und wertgeschätzt fühlen, am besten motiviert und engagiert bleiben. Durch die von uns angebotenen Vergünstigungen und Leistungen schaffen wir ein gesundes, unterstützendes und gerechtes Arbeitsumfeld für unsere Teammitglieder.",

      DepartmentAllText: "Alle",
      Department1: "Web Entwicklung",
      Department2: "Grafikdesign",
      Department3: "DevOps",
      Department4: "Marketing",

      office1: "Pakistan",
      office2: "Germany",

      ApiError:
        "Es tut uns leid, dass derzeit keine Jobs verfügbar sind. bitte versuche es später noch einmal",
      ApiErrorShortDescription:
        "Gap Dynamics sucht Experten und Ausreißer für unser Team. Wenn Sie Interesse haben, Teil unseres Teams zu werden, senden Sie uns bitte Ihren Lebenslauf an: careers@gapdynamics.de",
      FeaturedWorkTitle: "AUSGEZEICHNETE WERKE",
      OpenRoles: "OFFENE STELLEN",
      OpenRolesHeading: "MÖCHTEN SIE DIE NÄCHSTE APP ENTWICKELN?",
      OpenRolesText:
        "Gap Dynamics entwickelt einzigartige innovative Produkte die begeistern und den Wert unserer Kunden steigern. Unser Team besteht aus Talenten, die typischerweise bei Start-ups anstelle von Agenturen, Unternehmen oder Designstudios zu finden sind. Werden Sie Teil des Gap Dynamics Teams!",
    },
    JobDetailpage: {
      MetaTitle: "Stellendetails | Über GAP Dynamics GmbH",
      LocationText: "Ort",
      JobDateText: "Zuletzt aktualisiert",
      WhatWeOfferText: "Was wir anbieten",
      ResponsibilitesHeading: "Rollen und Verantwortlichkeiten",
      IdealCandidateHeading: "Idealer Kandidat",
      Perks: "Vorteile",
      ApplyBtn: "Jetzt bewerben",
      CardBoxHeading: "Haben Sie das Zeug dazu?",
      CardBoxDescription: "Senden Sie uns Ihren Lebenslauf/Lebenslauf      ",
      CardBoxShortDescription:
        "Möchten Sie dem Team in GER beitreten? Senden Sie Ihre Bewerbung – wir beantworten die meisten Anfragen innerhalb von drei Werktagen.      ",
      CardBoxButtonText: "Jetzt bewerben",
      BelowCardHeading: "Auf der Suche nach etwas Ähnlichem?",
      BelowCardLinkText: "Alle Jobs anzeigen",
      EmptyPropertyMsg: "Nicht festgelegt",
      LifeText: " Life at Gap Dynamics",
      LifeTextp:
        "Wir sind ein junges Team, das nicht nur Grenzen überschreitet und nach Spitzenleistungen strebt, sondern auch durch Zusammenarbeit, Transparenz und gemeinsame Verantwortung Respekt und Wertschätzung füreinander zeigt.",
      ValuesText: "Werte",
      ValuesTextp:
        "Unser Team lebt unsere Grundwerte durch Lob, Anerkennung und Leistungsbewertung aktiv vor. Wir achten darauf, Teammitglieder zu feiern, die unsere Werte in herausragender Weise vorleben und unser gesamtes Unternehmen dazu anspornen, besser zu werden.",
      PerksText: "Vergünstigungen & Vorteile",
      PerksTextp:
        "Wir sind davon überzeugt, dass Teammitglieder, die sich gut aufgehoben und wertgeschätzt fühlen, am besten motiviert und engagiert bleiben. Durch die von uns angebotenen Vergünstigungen und Leistungen schaffen wir ein gesundes, unterstützendes und gerechtes Arbeitsumfeld für unsere Teammitglieder.",
    },
    JobsApplyPage: {
      MetaTitle: "Stellenbewerbung | Über GAP Dynamics GmbH",
      JobTitleText: "Berufsbezeichnung",
      ApplyFormHeading: "Für diesen Job bewerben",
      WhatWeOfferText: "Was wir anbieten",
      ResponsibilitesHeading: "Rollen und Verantwortlichkeiten",
      ApplyFormSubHeading: "(*) Füllen Sie alle erforderlichen Felder aus",
      FormSubmitButtonText: "Einreichen",
      EmptyPropertyMsg: "Nicht festgelegt",
      IdealCandidateHeading: "Idealer Kandidat",

      JobApplyForm: {
        InvalidEmail: "Bitte eine gültige E-Mail-Adresse eintragen",
        InvalidWebsiteUrl: "Ungültige URL eingegeben",
        InvalidPhone: "Ungültige Nummer",
        AllFieldsRequired: "Bitte Pflichtfelder eingeben",
        SuccessMessage:
          "Super, Sie haben sich erfolgreich für diese Stelle beworben",
        ServerError:
          "Es ist ein Serverfehler aufgetreten. Bitte versuchen Sie es später erneut",
        CvError: "Laden Sie Ihren Lebenslauf hoch",
      },

      JobApplyFormFieldsPlaceHolders: {
        FirstName: "Vorname",
        LastName: "Familienname, Nachname",
        Email: "Email",
        CurrentCompany: "Derzeitige Firma",
        Phone: "Telefon +4917662775566",
        WebsiteUrl: "Website oder Portfolio",
        LinkedInUrl: "LinkedIn URL",
        Availibility: "Availibility",
        HeardAbout: "Davon gehört",
        Cv: "Lebenslauf hochladen (pdf)",
        CoverLetter: "Anschreiben hochladen (pdf)",
      },
    },

    PrivacyPolicyPage: {
      MetaTitle: "Impressum | Über GAP Dynamics GmbH",
      PageTitle: "Impressum",
      BannerButtonText: "Weiterlesen",

      CompanyName: "GAP Dynamics",
      Address1: "Prinz-Georg-Str 114",
      Address2: "40479 Düsseldorf",

      PhoneTitle: "Telefon",
      Phone: "(+49) (176) 62775566",
      PhoneLink: "tel:+4917662775566",

      EmailTitle: "E-Mail",
      Email: "hello@gapdynamics.de",
      EmailLink: "mailto:hello@gapdynamics.de",

      CEOTitle: "CEO",
      CEOName: "Gohar Paracha",
      RegisteredTypeTitle: "Handelsregister",
      RegisteredType: "Amtsgericht Düsseldorf (in Gründung)      ",
      MoreInfoTitle: "Weitere Informationen      ",
      MoreInfo:
        "Unsere Allgemeinen Geschäftsbedingungen finden Sie hier zum Download.      ",
      Paragraph1:
        "GAP Dynamics ® ist ein eingetragenes Warenzeichen der GAP Dynamics",
      Paragraph2:
        "GAP Dynamics ist für den Inhalt externer Angebote, auf die von dieser Website verwiesen wird, nicht verantwortlich.",
    },
  },
};
