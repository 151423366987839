import { createApp } from "vue";

import "@/assets/scss/main.scss";

import App from "./App.vue";

import "./index.css";

import "../public/assets/css/ant-design-vue.css";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

// import axios  from "axios";
import { Col, Row, Divider, Button, Drawer, Radio } from "ant-design-vue";

import VueSmoothScroll from "vue3-smooth-scroll";

import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { MotionPlugin } from '@vueuse/motion'
import router from "./router/index";
import Vue3Marquee from "vue3-marquee";
import "vue3-marquee/dist/style.css";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const myApp = createApp(App)
  .use(router)
  .use(router)
  .use(router)
  .use(VueSmoothScroll)
  .use(Divider)
  .use(Button)
  .use(Drawer)
  .use(Radio)
  .use(Col)
  .use(Row)
  .use(MotionPlugin)
  .component("LottieAnimation", LottieAnimation, Vue3Marquee);
myApp.mount("#app");
