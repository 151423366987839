export const SeoStrings = {
   "en":{
      "Home":{
         "Description":"Gap dynamics is a Germany-based custom software development company with offices located in different countries. We offer the best IT solutions, affordable solutions.",
         "Keywords":"software development company, custom software development company, software development services, custom software development, IT solutions, IT services",
         "HreflangDefault":"https://gap-dynamics.com/",
         "HreflangCountry":"https://gap-dynamics.com/",
         "OpenGraph":{
            "Title":"Gap Dynamics is an international software house that builds custom software",
            "SiteName":"Gap Dynamics",
            "Url":"https://gap-dynamics.com/",
            "Description":"Gap dynamics is a Germany-based custom software development company with offices located in different countries. We offer the best IT solutions, affordable solutions.",
            "Type":"website",
            "Image":"https://www.google.com/search?q=gap+dynamics&sxsrf=AOaemvJGT7jbxEB8Cbmcx-6aZA2AVbC_CA:1632485057506&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjykNDfyJfzAhWj4YUKHSKrDWUQ_AUoAXoECAEQAw&biw=1920&bih=969&dpr=1#imgrc=txz8WZ2g8vklHM"
         },
         "TwitterCard":{
            "Card":"summary",
            "Site":"@",
            "Title":"Gap Dynamics is an international software house that builds custom software",
            "Description":"Gap dynamics is a Germany-based custom software development company with offices located in different countries. We offer the best IT solutions, affordable solutions.",
            "Image":"https://gap-dynamics.com/"
         }
      },
      "Contact":{
         "Description":"Do you have any issues or projects in mind? Whether web, mobile development, graphic designing trust gap dynamics to offer the best customised solutions for your business. Contact us today!",
         "Keywords":"Software development company, customized solutions",
         "HreflangDefault":"https://gap-dynamics.com/contact",
         "HreflangCountry":"https://gap-dynamics.com/contact",
         "OpenGraph":{
            "Title":"",
            "SiteName":"",
            "Url":"",
            "Description":"",
            "Type":"",
            "Image":""
         },
         "TwitterCard":{
            "Card":"",
            "Site":"",
            "Title":"",
            "Description":"",
            "Image":""
         }
      },
      "Services":{
         "Description":"Gap Dynamics offers the best services which include custom software development, mobile application development, digital marketing and SEO service, UI/UX, ERP system, graphic designing for you.",
         "Keywords":"Affordable SEO services, digital marketing services, software development services, mobile application development service, mobile application development services, software development service, ERP system, UI/UX, Graphic Designing,",
         "HreflangDefault":"https://www.gap-dynamics.com/services",
         "HreflangCountry":"https://www.gap-dynamics.com/services",
         "OpenGraph":{
            "Title":"",
            "SiteName":"",
            "Url":"",
            "Description":"",
            "Type":"",
            "Image":""
         },
         "TwitterCard":{
            "Card":"",
            "Site":"",
            "Title":"",
            "Description":"",
            "Image":""
         }
      },
      "Projects":{
         "Description":"Gap Dynamics projects that help enterprise clients, startups, and many other companies’ projects that develop dynamic software solutions. We have 10 years of experience in software development.",
         "Keywords":"Software development projects, Software Engineering projects, Software development",
         "HreflangDefault":"https://www.gap-dynamics.com/projects",
         "HreflangCountry":"https://www.gap-dynamics.com/projects",
         "OpenGraph":{
            "Title":"",
            "SiteName":"",
            "Url":"",
            "Description":"",
            "Type":"",
            "Image":""
         },
         "TwitterCard":{
            "Card":"",
            "Site":"",
            "Title":"",
            "Description":"",
            "Image":""
         }
      },
      "Jobs":{
         "Description":"Gap Dynamics is a fast-growing software development company that provides great carrier opportunities to freshers and experienced persons along with attractive benefits.",
         "Keywords":"Gap Dynamics, software development company, Software Development Jobs",
         "HreflangDefault":"https://gap-dynamics.com/jobs",
         "HreflangCountry":"https://gap-dynamics.com/jobs",
         "OpenGraph":{
            "Title":"",
            "SiteName":"",
            "Url":"",
            "Description":"",
            "Type":"",
            "Image":""
         },
         "TwitterCard":{
            "Card":"",
            "Site":"",
            "Title":"",
            "Description":"",
            "Image":""
         }
      },
      "Team":{
         "Description":"Gap Dynamics is a custom software development company. They offer the best solutions in digital marketing, web development, SEO services, and graphic designing.",
         "Keywords":"Software development Company, custom software development company, Digital marketing, web development, graphic designing, seo services",
         "HreflangDefault":"https://gap-dynamics.com/about-us",
         "HreflangCountry":"https://gap-dynamics.com/about-us",
         "OpenGraph":{
            "Title":"",
            "SiteName":"",
            "Url":"",
            "Description":"",
            "Type":"",
            "Image":""
         },
         "TwitterCard":{
            "Card":"",
            "Site":"",
            "Title":"",
            "Description":"",
            "Image":""
         }
      },
      "TeamDetails":{
         "Description":"",
         "Keywords":"",
         "HreflangDefault":"",
         "HreflangCountry":"",
         "OpenGraph":{
            "Title":"",
            "SiteName":"",
            "Url":"",
            "Description":"",
            "Type":"",
            "Image":""
         },
         "TwitterCard":{
            "Card":"",
            "Site":"",
            "Title":"",
            "Description":"",
            "Image":""
         }
      }
   },
   "gr":{
      "Home":{
         "Description":"",
         "Keywords":"",
         "HreflangDefault":"",
         "HreflangCountry":"",
         "OpenGraph":{
            "Title":"",
            "SiteName":"",
            "Url":"",
            "Description":"",
            "Type":"",
            "Image":""
         },
         "TwitterCard":{
            "Card":"",
            "Site":"",
            "Title":"",
            "Description":"",
            "Image":""
         }
      },
      "Contact":{
         "Description":"",
         "Keywords":"",
         "HreflangDefault":"",
         "HreflangCountry":"",
         "OpenGraph":{
            "Title":"",
            "SiteName":"",
            "Url":"",
            "Description":"",
            "Type":"",
            "Image":""
         },
         "TwitterCard":{
            "Card":"",
            "Site":"",
            "Title":"",
            "Description":"",
            "Image":""
         }
      },
      "Services":{
         "Description":"",
         "Keywords":"",
         "HreflangDefault":"",
         "HreflangCountry":"",
         "OpenGraph":{
            "Title":"",
            "SiteName":"",
            "Url":"",
            "Description":"",
            "Type":"",
            "Image":""
         },
         "TwitterCard":{
            "Card":"",
            "Site":"",
            "Title":"",
            "Description":"",
            "Image":""
         }
      },
      "Projects":{
         "Description":"",
         "Keywords":"",
         "HreflangDefault":"",
         "HreflangCountry":"",
         "OpenGraph":{
            "Title":"",
            "SiteName":"",
            "Url":"",
            "Description":"",
            "Type":"",
            "Image":""
         },
         "TwitterCard":{
            "Card":"",
            "Site":"",
            "Title":"",
            "Description":"",
            "Image":""
         }
      },
      "Jobs":{
         "Description":"",
         "Keywords":"",
         "HreflangDefault":"",
         "HreflangCountry":"",
         "OpenGraph":{
            "Title":"",
            "SiteName":"",
            "Url":"",
            "Description":"",
            "Type":"",
            "Image":""
         },
         "TwitterCard":{
            "Card":"",
            "Site":"",
            "Title":"",
            "Description":"",
            "Image":""
         }
      },
      "Team":{
         "Description":"",
         "Keywords":"",
         "HreflangDefault":"",
         "HreflangCountry":"",
         "OpenGraph":{
            "Title":"",
            "SiteName":"",
            "Url":"",
            "Description":"",
            "Type":"",
            "Image":""
         },
         "TwitterCard":{
            "Card":"",
            "Site":"",
            "Title":"",
            "Description":"",
            "Image":""
         }
      },
      "TeamDetails":{
         "Description":"",
         "Keywords":"",
         "HreflangDefault":"",
         "HreflangCountry":"",
         "OpenGraph":{
            "Title":"",
            "SiteName":"",
            "Url":"",
            "Description":"",
            "Type":"",
            "Image":""
         },
         "TwitterCard":{
            "Card":"",
            "Site":"",
            "Title":"",
            "Description":"",
            "Image":""
         }
      }
   }
};