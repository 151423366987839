<style scoped>
.mobRes {
  text-decoration: none;
  font-size: 3rem;
  font-size: 18px;
}
h2 a:hover footer {
  background: #c70000 !important;
}
.contact-num a {
  font-size: 48px !important;
  font-family: UnitedSansCondHeavy;
}

.bg-dark {
  background: #c70000;
}

#countries-list {
  margin: 20px 10px;
  justify-content: center;
}
@media screen and (min-width: 200px) and (max-width: 1099px) {
  .mobRes {
    font-size: 2.2rem;
  }
}
</style>

<template>
  <footer class="footer-box">
    <div>
      <div class="footer-main">
        <div class="want-to-talk" style="align-self: self-start">
          <!-- <span class="text" v-if="!$route.meta.contactpage">
            {{ content.OneLineDescription }}
          </span>
          <span class="text" v-else
            >{{ content.FooterOneLineDescription }}
          </span> -->

          <div class="bg-dark"></div>
          <h2 v-if="!$route.meta.contactpage">
            <router-link
              :title="content.AltText"
              class="email"
              :to="{ name: 'Contact' }"
            >
              {{ content.Email }}
            </router-link>
          </h2>

          <h2 v-else>
            <a :title="content.AltText" class="email" :href="content.mailto">
              {{ content.FooterEmail }}
            </a>
          </h2>

          <div>
            <h2 class="contact-num" v-if="$route.meta.contactpage">
              <span v-if="siteLanguage == 'GR'">
                <a title="Email" class="mobRes" href="+49 (176) 62775566">
                  +49 (176) 62775566</a
                >
              </span>

              <span v-else>
                <!-- <a title="Call us" class="mobRes" href="tel:+4917662775566"
                    >hello@gapdynamics.de</a
                  >
                  <br /> -->

                <a title="Email" class="mobRes" href="00971554430935"
                  >+968 9120 0180
                </a>
              </span>
            </h2>
            <div id="countries-list" class="flex">
              <p>Düsseldorf</p>
              &nbsp; &nbsp; &nbsp;
              <p>London</p>
              &nbsp; &nbsp; &nbsp;
              <p>Islamabad</p>
              &nbsp; &nbsp; &nbsp;
              <p>Muscat</p>
              &nbsp; &nbsp; &nbsp;
              <p>Dubai</p>
            </div>
          </div>
          <div class="bg-dark"></div>
          <!-- <div id="social-media-icons" class="flex">
            <a href="https://www.facebook.com/thegapdynamics"
              ><Facebook viewBox="0 0 40 50"
            /></a>
            <a href="https://www.instagram.com/thegapdynamics/"
              ><Instagram
            /></a>
            <a href="https://twitter.com/thegapdynamics"><Twitter /></a>
            <a href="https://www.linkedin.com/company/thegapdynamics"
              ><Linkedin viewBox="0 0 20 25"
            /></a>
            <a href="https://www.youtube.com/channel/UCRmHVzVqWVCKT8fFDqtOglg"
              ><Youtube viewBox="0 0 50 30"
            /></a>
          </div> -->
        </div>
        <div class="footer-dark-bg">
          <div class="footer-links">
            <div>
              <ul class="footer-list">
                <li>
                  <router-link
                    :to="{
                      name: 'Services',
                      query: { section: 'digitalTransformation' },
                    }"
                    >{{ content.digitalText }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{ name: 'Services', query: { section: 'blockchain' } }"
                    >{{ content.blockchainText }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{ name: 'Services', query: { section: 'web' } }"
                    >{{ content.webText }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{ name: 'Services', query: { section: 'appDev' } }"
                    >{{ content.appText }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{ name: 'Services', query: { section: 'design' } }"
                    >{{ content.designText }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{
                      name: 'Services',
                      query: { section: 'digitalMarketing' },
                    }"
                    >{{ content.marketingText }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div>
              <ul class="footer-list">
                <li>
                  <router-link :to="{ name: 'Services' }">{{
                    content.serviceText
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Projects' }">{{
                    content.projectsText
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'About' }">{{
                    content.teamText
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Jobs' }">{{
                    content.careersText
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Blog' }">{{
                    content.blogText
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Contact' }">{{
                    content.contactText
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="main-footer-estb">
            <p>{{ content.establishText }}</p>
            <h2 class="footer-contact-info">
              <span v-if="siteLanguage == 'GR'">
                <a
                  title="Email"
                  class="mobRes"
                  href="mailto:hello@gapdynamics.de"
                  >hello@gapdynamics.de</a
                >
              </span>

              <span v-else>
                <!-- <a title="Call us" class="mobRes" href="tel:+4917662775566"
                    >hello@gapdynamics.de</a
                  >
                  <br /> -->

                <a
                  title="Email"
                  class="mobRes"
                  href="mailto:hello@gap-dynamics.com"
                  >hello@gap-dynamics.com
                </a>
              </span>
            </h2>
            <p v-if="siteLanguage == 'GR'">
              <router-link
                :to="{ name: 'Impressum' }"
                style="text-decoration: none"
              >
                {{ content.PrivacyPolicyPageText }}
              </router-link>
            </p>
            <p v-else>
              <router-link
                :to="{ name: 'privacy-policy' }"
                style="text-decoration: none"
              >
                {{ content.PrivacyPolicyPageText }}
              </router-link>
            </p>
          </div>
        </div>

        <div class="bg-dark"></div>
      </div>

      <!-- <div class="bottom">
        <div id="social-media-mobile-wrapper">
          <div id="social-media-icons" class="flex">
            <a href="https://www.facebook.com/thegapdynamics"
              ><Facebook viewBox="0 0 40 50"
            /></a>
            <a href="https://www.instagram.com/thegapdynamics/"
              ><Instagram
            /></a>
            <a href="https://twitter.com/thegapdynamics"><Twitter /></a>
            <a href="https://www.linkedin.com/company/thegapdynamics"
              ><Linkedin viewBox="0 0 20 28"
            /></a>
            <a href="https://www.youtube.com/channel/UCRmHVzVqWVCKT8fFDqtOglg"
              ><Youtube viewBox="0 0 50 15"
            /></a>
          </div>
        </div> -->
      <!-- <p>
          <a
            class="hover-color"
            title="Email us"
            style="text-decoration: none"
               :href="content.mailto" 
            >{{ content.Email }}</a
          >
        </p> -->
      <!-- <div id="countries-list" class="flex" v-if="siteLanguage == 'EN'"> -->
      <!-- <p>Germany</p>
            <p>United Kingdom</p>
            <p>Pakistan</p> -->
      <!-- </div> -->
      <!-- </div> -->
    </div>
  </footer>
</template>
<script>
import { LanguageStrings } from "@/languages";

import { useRoute } from "vue-router";
// import Twitter from "@/assets/icons/twitter.svg";
// import Linkedin from "@/assets/icons/linkedin.svg";
// import Instagram from "@/assets/icons/instagram.svg";
// import Facebook from "@/assets/icons/facebook.svg";
// import Youtube from "@/assets/icons/youtube.svg";

export default {
  components: {
    // Facebook,
    // Instagram,
    // Twitter,
    // Linkedin,
    // Youtube,
  },

  data() {
    return {
      siteLanguage: localStorage.getItem("siteLanguage"),
      content: null,
      path: useRoute().name,
      isMobile: false,
      localClientIp: localStorage.getItem("localClientIp"),
    };
  },
  created() {
    //  this.path = this.$route.name === "Contact"

    // this.path=useRoute().name

    localStorage.getItem("siteLanguage") == "GR"
      ? (this.content = LanguageStrings.gr.Footer)
      : (this.content = LanguageStrings.en.Footer);
  },

  //   computed: {
  //   getRoutePath() {
  //     return this.$route.name;
  //   },
  // },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
